import Title from "../../../../../../../utils/title/Title";
import { Colors } from "../../../../../../../utils/colors/colors";
import styled from "styled-components";
import { useTimeToRead } from "../../../../../../../hooks/useTimeToRead";
import { useCreatedAt } from "../../../../../../../hooks/useCreatedAt";
import DOMPurify from 'dompurify';




const DateAndTime = styled.div`
  font-size: small;
  font-weight: 500;
  color: #2b2b2b;
`;

const BlogBody = styled.article`
  font-size: 1.1rem;
  line-height: 2rem;
  margin-top: 2rem;
`;

export default function Article({ id, blogTitle, blogBody, date, author }) {
  const { fourthColor } = Colors();

  return (
    <>
      <DateAndTime
        style={{
          color: fourthColor,
        }}
      >
        {author} &middot; {useCreatedAt(date)} &middot;{" "}
        {useTimeToRead(blogBody)} min read
      </DateAndTime>

      <Title
        textColor={fourthColor}
        text={blogTitle}
        textAlign="left"
        style={{
          fontWeight: "600",
        }}
      />

      <BlogBody>
        <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(blogBody) }}>
        </p>
      </BlogBody>
    </>
  );
}
