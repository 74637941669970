import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import authService from "../services/auth.service";
import { AUTH } from ".";
import { roles } from "../constants/constants";





const currentUser = authService.currentUser();
const isAuthenticated = authService.isAuthenticated();


export const ProtectedRoute = () => {
  return isAuthenticated ? <Outlet /> : <Navigate to={AUTH} />;
};


export const AdminRoute = () => {

  return currentUser?.role === roles.ADMIN ? <Outlet /> : <Navigate to={AUTH} />;
};

export const CMSAdminRoute = () => {

  return (currentUser?.role === roles.CMS_ADMIN || currentUser?.role === roles.ADMIN) ? <Outlet /> : <Navigate to={AUTH} />;
};

export const HRAdminRoute = () => {

  return (currentUser?.role === roles.HR_ADMIN || currentUser?.role === roles.ADMIN) ? <Outlet /> : <Navigate to={AUTH} />;
};