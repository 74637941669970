import React from 'react';
import SubTitle from '../../../utils/subtitle/SubTitle';
import Paragraph from '../../../utils/paragraph/Paragraph';

function Text({ textColor, text, textAlign, style, subTitleTextColor, subTitleTextOne, subTitleTextTwo, subTitleTextAlign, subTitleStyle }) {

	return (
		<>
			<SubTitle
				textColor={textColor}
				text={text}
				textAlign={textAlign}
				style={style}
			/>

			<Paragraph
				textColor={subTitleTextColor}
				text={subTitleTextOne}
				textAlign={subTitleTextAlign}
				style={subTitleStyle}
			/>

			<Paragraph
				textColor={subTitleTextColor}
				text={subTitleTextTwo}
				textAlign={subTitleTextAlign}
				style={subTitleStyle}
			/>
		</>
	);
}

export default Text;