import styled from 'styled-components';
import SubTitle from '../../utils/subtitle/SubTitle';

const CardStyles = styled.div`
	height: 300px;
	max-height: 400px;
	max-width: 400px;
	border-radius: 4px;
	filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.04));
	background-color: rgba(0, 0, 0, 1);
	display: flex;
	justify-content: center;
	align-items: center;
	background-repeat: no-repeat;
	background-style: cover;
  background-position: center;
	transition: linear all 0.6s;
  padding: 0;

	:hover {
		transform: scale(1.1, 1.1);
	}
`;

function Card({ cardText, cardBg }) {
  return (
    <CardStyles
      style={{
        backgroundImage: `url(${cardBg})`,
        margin: "1rem auto" }}
      data-aos="fade-up"
      data-aos-once="true"
    >
      <SubTitle
        textColor="#fff"
        text={cardText}
        textAlign="center"
        style={{ fontWeight: "bold" }}
      />
    </CardStyles>
  );
}

export default Card;