import AuthCard from '../../AuthCard';
import FormComponent from './FormComponent';


const Right = () => {
  return (
    <AuthCard
      actionText="Reset Password"
      actionSubText="Enter your email to reset your password"
    >
      <FormComponent>

      </FormComponent>
    </AuthCard>
  );
}

export default Right;