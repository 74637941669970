import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Title from '../../../utils/title/Title';
import SubTitle from '../../../utils/subtitle/SubTitle';
import './hero.css';
import ButtonComponent from '../../../utils/button/ButtonComponent';
import { Colors } from '../../../utils/colors/colors';
import styled from 'styled-components';




const navigateTo = (URL) => {
  window.location.href = URL;
};

const handleContactBtn = () => {
  navigateTo("#contact");
};

const handleResumeBtn = () => {
  navigateTo("https://eraconneckt.com#resume");
};



const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem
`;


function HeroContent() {
  const heroStyles = {
    textColor: "#fff",
    titleStyles: {
      fontWeight: "900",
    },
    subTitleStyles: {
      fontWeight: "500",
    }
  };


  const {
    primaryColor
  } = Colors();

  return (
    <Container>
      <Row>
        <Col
        >
          <Title
            text="Positioning African Youth for Global Opportunities"
            textColor={heroStyles.textColor}
            textAlign="center"
            style={heroStyles.titleStyles}
          />
        </Col>
      </Row>


      <Row>
        <Col
          className="mt-3"
          style={{ width: "100%", maxWidth: "750px", margin: "0 auto" }}
        >
          <SubTitle
            text="At Eraconneckt, we recruit people from multiple disciplines, and empower them with digital skills to compete in the global technology environment."
            textColor={heroStyles.textColor}
            textAlign="center"
            style={heroStyles.subTitleStyles}
          />


          <ButtonsContainer>
            <ButtonComponent
              bgColor={primaryColor}
              text="Contact Us"
              playIcon=""
              style={{
                border: `2px solid ${primaryColor}`,
                margin: "auto 8px"
              }}
              click={handleContactBtn}
            />

            <ButtonComponent
              bgColor="transparent"
              text="Send Resume"
              playIcon=""
              style={{
                border: `2px solid ${primaryColor}`,
                margin: "auto 8px"
              }}
              click={handleResumeBtn}
            />
          </ButtonsContainer>

        </Col>

      </Row>
    </Container>

  );
}

export default HeroContent;