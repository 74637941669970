import styled from 'styled-components';
import { useExport } from '../../../../../../../hooks/useExport';

const ShareContainer = styled.div`
  position: sticky;
  top: 10rem;
`;

const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

    @media (min-width: 768px) {
      flex-direction: column;
    }

`;

const Li = styled.li`
  list-style: none;
  display: inline-block;
  margin-bottom: 2rem;
  margin-right: 1rem;
`;

const Img = styled.img`

`;

export default function SocialSharing({ id, blogTitle }) {

  const pageURL = window.location.href;

  const {
    facebookShare,
    twitterShare,
    linkedinShare
  } = useExport();

  return (
    <ShareContainer>
      <Ul>

        <Li>
          Share
        </Li>

        <Li>
          <a
            hrefLang="en-us"
            href={`https://www.linkedin.com/shareArticle?mini=true&url=${pageURL}`}
            target="_blank"
            rel="noreferrer"
            title="Share on LinkedIn"
          >
            <Img
              src={linkedinShare}
              alt="Share on LinkedIn"
            />
          </a>
        </Li>

        <Li>
          <a
            hrefLang="en-us"
            href={`https://twitter.com/intent/tweet?url=${pageURL}&text=${blogTitle}`}
            target="_blank"
            rel="noreferrer"
            title="Share on Twitter"
          >
            <Img
              src={twitterShare}
              alt="Share on Twitter"
            />
          </a>
        </Li>

        <Li>
          <a
            hrefLang="en-us"
            href={`https://www.facebook.com/sharer/sharer.php?u=${pageURL}`}
            target="_blank"
            rel="noreferrer"
            title="Share on Facebook"
          >
            <Img
              src={facebookShare}
              alt="Share on Facebook"
            />
          </a>
        </Li>

      </Ul>
    </ShareContainer>
  )
}