import { useState, useEffect, Fragment } from 'react';
import { Container } from 'react-bootstrap';
import Navigation from '../../../components/navbar';
import BlogsCard from "./../../../components/blog/components/blogs-cards/index";
import Footer from '../../../components/footer';
import { Link, useLocation } from "react-router-dom";
import { useLocationPathName } from "./../../../hooks/useLocationPathname";
import { Colors } from '../../../utils/colors/colors';
import styled from 'styled-components';
import ButtonComponent from '../../../utils/button/ButtonComponent';
import { ADMIN_WRITE, ADMIN_DRAFT } from '../../../routes';







export default function AdminBlogComponent() {


  const location = useLocation();

  const { result } = useLocationPathName(location);

  const [title, setTitle] = useState("Eraconneckt | Blog");

  const {
    primaryColor,
    secondaryColor
  } = Colors()


  useEffect(() => {


    setTitle(`Eraconneckt | Blog`);

  }, []);


  useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    document.title = title;
  }, [title]);



  return (
    <>
      <Navigation />

      <Container>
        <Header
          className="heading"
        >
          <p
            style={{
              marginTop: 0,
              marginBottom: 0,
            }}
          >
            {result}
          </p>

          <LinkWrapper>
            <Link
              to={ADMIN_DRAFT}
            >
              <p
                style={{
                  marginTop: 0,
                  marginBottom: 0,
                  color: secondaryColor,
                }}
              >
                Blog drafts
              </p>
            </Link>
            <ButtonComponent
              href={ADMIN_WRITE}
              text={`Write Blog`}
              bgColor={primaryColor}
              style={``}
              click={``}
              variant={``}
              isLoading={``}
              cName={`border-transparent`}
            />

          </LinkWrapper>
        </Header>
      </Container>


      <div
        style={{
          position: "relative",
          top: "25vh"
        }}>

        <BlogsCard
          slug="blog"
        />

      </div>

      <Footer />

    </>
  )
}




const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 7rem;
`;

const LinkWrapper = styled.div`
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
`;