import styled from "styled-components";


const Label = styled.label`
  display: none;
`;

const Input = styled.input`
  margin: 0;
  font-size: 1rem;
  cursor: pointer;

    @media (min-width: 768px) {
      padding-right: 5px;
    }
`;





export default function DateComponent({ blogDate, setBlogDate }) {


  const handleDateChange = (selectedDate) => {

    // Convert the gotten date to timestamp

    const date = new Date(selectedDate);

    const timestampSeconds = Math.floor(date.getTime() / 1000);

    setBlogDate(timestampSeconds.toString());

    // Useful date
    // console.log(timestampSeconds.toString());
  };




  return (
    <>
      <Label
        htmlFor="start">
        Start date:
      </Label>

      <Input
        className={`blog-tools-form__input ${!blogDate ? '' : ''}`}
        type="date"
        id="start"
        name="date"
        min="2018-01-01"
        max="2399-12-31"
        defaultValue={blogDate}
        onChange={(e) => handleDateChange(e.target.value)}
      />
    </>
  );
}