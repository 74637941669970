import Switch from "react-switch";
import axios from 'axios';
import { constants } from "../../../constants/constants";
import { toast } from "react-toastify";
import authHeader from "./../../../services/auth-header";



const baseURL = `${constants.API_URL}/auth`;






const headers = authHeader();


export const SwitchComponent = ({
  status, id
}) => {


  const setAdminActive = () => {
    toast.info(`Updating role ...`);


    axios
      .put(`${baseURL}/${id}`,
        {
          "status": "active"
        },
        { headers }
      )
      .then((response) => {
        // console.info(response);
        toast.success(`Admin role updated`);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }).catch((response) => {
        toast.error(`Something went wrong. Admin role not updated`);
        console.error(response.data);
      });
  };


  const setAdminInActive = () => {
    toast.info(`Updating role ...`);



    axios
      .put(`${baseURL}/${id}`,
        {

        },
        { headers }
      )
      .then((response) => {
        // console.info(response);
        toast.success(`Admin role updated`);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }).catch((response) => {
        toast.error(`Something went wrong. Admin role not updated`);
        console.error(response.data);
      });
  };



  const handleChange = (nextChecked) => {
    status === "active" ? setAdminInActive() : setAdminActive();
  };


  return (
    <>
      <Switch
        onChange={handleChange}
        checked={status === "active" ? true : false}
        className="react-switch"
        handleDiameter={24}
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow="0"
        activeBoxShadow="0"
        height={28}
        width={46}
        id="material-switch"
        offColor="#B9B9B9"
        onColor="#89B369"
        offHandleColor="#ffffff"
        onHandleColor="#ffffff"
      />
    </>
  )
}