import React, { useEffect } from 'react'
import Navigation from '../../navbar'
import BlogTextArea from '../../blog/components/blog-textarea/BlogTextArea'
import { useState } from 'react';
import { useFetch } from '../../../hooks/useFetch';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import authHeader from "../../../services/auth-header";
import { constants } from '../../../constants/constants';
import JobTools from './job-tools/JobTools';
import { ADMIN_CREATE_JOB, ADMIN_JOB } from '../../../routes';













export default function JobComponent() {


  const API_URL = constants.API_URL;
  const headers = authHeader();


  const [position, setPosition] = useState("");
  const [level, setLevel] = useState("");
  const [jobType, setJobType] = useState("");
  const [description, setDescription] = useState("");
  const [job, setJob] = useState({});
  const [isJobScreen, setIsJobScreen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [jobLocation, setJobLocation] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [postError, setPostError] = useState("");
  const [title, setTitle] = useState("Eraconneckt | Admin");
  const [JOB_API_URL, set_JOB_API_URL] = useState("");
  const pathname = location.pathname;
  const [isCreating, setIsCreating] = useState(false);
  const pathArray = window.location.pathname.split('/');
  const slug = pathArray[3];




  const {
    isPending, data
  } = useFetch(JOB_API_URL, {});


  useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    document.title = title;
  }, [title]);


  useEffect(() => {
    setIsJobScreen(slug?.toLocaleLowerCase() === "job" ? true : false);
  }, [slug]);



  useEffect(() => {

    // Creating job

    if (pathname === ADMIN_CREATE_JOB ) {
      setTitle("Create Job Post | Eraconneckt");
      set_JOB_API_URL(`${API_URL}/jobs`);
      setIsCreating(true);
    }

    // Editing job post
    else {
      set_JOB_API_URL(`${API_URL}/jobs/${id}`);




      if (!isPending) {


        setJob(data.data);


        // Set blog data back if Editing

        setLevel(job.level);
        setDescription(job.blogBody);
        setPosition(job.position);
        setJobType(job.jobType);


        setTitle(`${job?.position ? job?.position : "Edit Job Post"} | Eraconneckt`);

      }
    }

  }, [pathname, API_URL, id, isPending, data.data, job.level, job.blogBody, job.position, job.jobType])




  const handlePublish = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const newJobPost = {
      position,
      level,
      description,
      jobLocation,
      jobType
    };


    if (newJobPost.position === "") {
      toast.error(`Please fill out the position field!`);
      setIsLoading(false);
    } else if (newJobPost.level === "") {
      toast.error(`Please fill out the level field!`);
      setIsLoading(false);
    } else if (newJobPost.description === "") {
      toast.error(`Please fill out the description field!`);
      setIsLoading(false);
    } else if (newJobPost.jobLocation === "") {
      toast.error(`Please fill out the location field!`);
      setIsLoading(false);
    } else if (newJobPost.jobType === "") {
      toast.error(`Please fill out the job type field!`);
      setIsLoading(false);
    } else {
      toast.info(`Publishing job post. Hang in tight...`);
    }



    axios.post(`${API_URL}/jobs`, newJobPost, { headers })
      .then((res) => {
        if (res?.status >= 200 && res?.status < 300) {
          toast.success("Post created successfully!");
          setIsLoading(false);
          setTimeout(() => {
            navigate(ADMIN_JOB);
            // eslint-disable-next-line no-restricted-globals
          }, 1000);
        }
      })
      .catch((err) => {
        toast.error("Something went wrong!");

        console.error(err);
        console.error(err?.message);
        setPostError(err?.response?.data.error);
        setIsLoading(false);
      });

  };




  const handleUpdate = (e) => {

    e.preventDefault();
    setIsLoading(true);

    const newJobPost = { position, level, description, jobLocation, jobType };





    if (newJobPost.position === "") {
      toast.error(`Please fill out the position field!`);
      setIsLoading(false);
    } else if (newJobPost.level === "") {
      toast.error(`Please fill out the level field!`);
      setIsLoading(false);
    } else if (newJobPost.description === "") {
      toast.error(`Please fill out the description field!`);
      setIsLoading(false);
    } else if (newJobPost.jobLocation === "") {
      toast.error(`Please fill out the location field!`);
      setIsLoading(false);
    } else if (newJobPost.jobType === "") {
      toast.error(`Please fill out the job type field!`);
      setIsLoading(false);
    } else {
      toast.info(`Updating job post. Hang in tight...`);
    }







    axios.put(JOB_API_URL, newJobPost, { headers })
      .then((res) => {
        if (res?.status >= 200 && res?.status < 300) {
          toast.success("Job post updated successfully!");
          setIsLoading(false);
          setTimeout(() => {
            navigate(ADMIN_JOB);
            // eslint-disable-next-line no-restricted-globals
          }, 1000);
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error("Something went wrong!");
        console.error(err?.message);
        setPostError(err?.response?.data.error);
        setIsLoading(false);
      });

  };


  return (


    <>
      <Navigation />


      <JobTools
        level={isCreating ? level : job.level}
        id={isCreating ? id : job.id}
        jobLocation={isCreating ? jobLocation : job.jobLocation}
        jobType={isCreating ? jobType : job.jobType}
        isLoading={isLoading}
        setLevel={setLevel}
        setJobType={setJobType}
        setJobLocation={setJobLocation}
        handlePublish={handlePublish}
        isCreating={isCreating}
        handleUpdate={handleUpdate}
        postError={postError}
        slug={slug}
      />


      <BlogTextArea
        blogTitle={isCreating ? position : job.position}
        setBlogTitle={setPosition}
        isJobScreen={isJobScreen}
        blogBody={isCreating ? description : job.description}
        setBlogBody={setDescription}
      />

    </>
  )
}
