import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import TableContent from "./TableContent";
import { Colors } from "../../../../src/utils/colors/colors";
import { MainTable } from "./MainTable";
import { toast } from "react-toastify";
import axios from "axios";
import authHeader from "../../../services/auth-header";
import { constants } from "../../../constants/constants";
import { authRedirection } from "../../../reducers";







const {
  primaryColor
} = Colors();

const TableContainer = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 0px;
margin: 1rem auto 0 auto;
max-width: 888px;
min-height: 330px;
max-height: 830px;
border: 0.5px solid ${primaryColor};
box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
border-radius: 8px;
overflow-y: auto;
`;


export default function AdminsTable() {

  const baseURL = `${constants.API_URL}/auth`;
  const headers = authHeader();
  const [showOverlay, setShowOverlay] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [admins, setAdmins] = useState("");
  const [adminsError, setAdminsError] = useState("");














  const handleSearchAdmins = () => {
    axios
      .get(`${baseURL}/?email=${searchString}`,
        { headers }
      )
      .then((response) => {
        // console.log(response);

        // SetAdmins if result > 0
        if (response.data.data.length > 0) {

          const lengthOfResult = response.data.data.length;
          if (lengthOfResult === 1) {
            toast.info(`${lengthOfResult} result found.`);
          } else {
            toast.info(`${lengthOfResult} results found.`);
          }
          setAdmins(response.data.data)
        };

        // Throw info if result < 1
        if (response.data.data.length < 1) {
          toast.info(`No result found. Please try again with a valid email.`);
        };

      }).catch((response) => {
        toast.error(`Something went wrong.`);
        console.error(response.data);
      });

    // Clear searchString
    setSearchString("");
  };

  const handleDeleteAdmin = (adminId, adminEmail) => {
    // eslint-disable-next-line no-restricted-globals
    const confirmation = confirm(`Are you sure you want to delete?`);

    if (confirmation === true) {
      fetch(`${baseURL}/${adminId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          ...headers
        },
        body: "",
      }).then((data) => {
        toast.success(`${adminEmail} successfully deleted!`)
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }).catch(err => {
        console.error("Error message: ", err)
        toast.error(`Error occurred. Could not delete ${adminEmail}.`);
      });
    };
  };


  useEffect(() => {
    axios
      .get(baseURL, { headers })
      .then((response) => {
        setAdmins(response.data.data);
      })
      .catch((err) => {
        // Handle error
        console.log(err);
        console.error(err.message);
        setAdminsError(err.message)
        console.log(adminsError);
        toast.error(`Error occurred. Please login and try again`);

        setTimeout(() => {
          authRedirection()
        }, 2000);
      });
  }, []);



  const handleSearch = (e) => {
    handleSearchAdmins();
  };

  const handleEnterKey = (e) => {
    if (e.key === "Enter") {
      handleSearchAdmins()
    };
  };


  const handleAddAdmin = (search) => {
    setShowOverlay(!showOverlay);
  };

  const handleSearchString = (e) => {
    setSearchString(e.target.value)
  };




  return (
    <>
      <Container>
        <Row>
          <Col>
            <TableContainer>

              <TableContent
                handleSearchString={handleSearchString}
                handleSearch={handleSearch}
                handleAddAdmin={handleAddAdmin}
                showOverlay={showOverlay}
                setShowOverlay={setShowOverlay}
                searchString={searchString}
                setSearchString={setSearchString}
                handleEnterKey={handleEnterKey}
              />
              <MainTable
                admins={admins}
                setAdmins={setAdmins}
                adminsError={adminsError}
                setAdminsError={setAdminsError}
                handleDeleteAdmin={handleDeleteAdmin}
              />

            </TableContainer>
          </Col>
        </Row>
      </Container>
    </>
  )
}