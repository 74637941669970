import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';



export default function Author({ blogAuthor, setBlogAuthor }) {



  const { register, formState: { errors }, handleSubmit } = useForm();

  const onSubmit = (data) => {
    setBlogAuthor(data.author);

    // console.log(`blogAuthor: ${blogAuthor}`);
  };




  return (
    <>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        onChange={handleSubmit(onSubmit)}
      >
        <Form.Control
          className={`blog-tools-form__input ${errors.author ? '' : ''}`}
          name="author"
          {...register("author", { required: "Please enter author's name" })}
          type="text"
          autoComplete="on"
          placeholder="Enter author's name"
          style={{
            backgroundColor: "",
            padding: "12px 28px",
          }}
          defaultValue={blogAuthor}
          onChange={(e) => setBlogAuthor(e.target.value)}
        />

        {/* {errors.author && (
          <p className="errorMsg">{errors.author.message}</p>
        )} */}

      </Form>
    </>
  )
}