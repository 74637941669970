import React, { useEffect, useState } from 'react';
import { Container, Navbar, Nav } from 'react-bootstrap';
import './Navbar.css';
import eraconnecktLogo from "../../assets/img/eraconneckt-logo.svg";
import { useLocation } from "react-router-dom";
import { useLocationPathName } from '../../hooks/useLocationPathname';
import { navLinks } from './data/navLinks';
import { HOME } from '../../routes';
import authService from '../../services/auth.service';






const logoStyles = {
  maxWidth: "150px",
};

const navBarStyles = {
  backgroundColor: "transparent",
  display: "flex",
  justifyContent: "center"
};


export default function Navigation() {

  const location = useLocation();
  const logout = authService.logout;
  const isAuthenticated = authService.isAuthenticated();
  const { pathLength } = useLocationPathName(location);

  const [scrolled, setScrolled] = useState(false);



  useEffect(() => {
    // Add gray background to navbar for good accessibility
    pathLength > 1 && setScrolled(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  document.addEventListener("scroll", () => window.scrollY > 100 ? setScrolled(true) : setScrolled(false));



  const handleMobileToggler = () => {
    setScrolled(true)
  };

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        style={navBarStyles}
        variant="light"
        className={`navbar-styles ${scrolled || isAuthenticated ? "dark-navbar" : ""}`}

      >
        <Container
        >
          <Navbar.Brand
            href={HOME}
          >
            <img
              src={eraconnecktLogo}
              style={logoStyles}
              alt="Eraconneckt logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle
            className="toggler-styles"
            aria-controls="responsive-navbar-nav"
            onClick={handleMobileToggler}
          />

          <Navbar.Collapse
            className="justify-content-end"
            id="responsive-navbar-nav">
            <Nav
              className="navbar-nav navbar-right"
            >

              {navLinks.map((link, index) => {
                return (
                  <Nav.Link
                    className="nav-links-styles"
                    onClick={() => link?.function()}
                    to={link?.url}
                    href={link?.url}
                    key={`${link?.id}${link?.name}${index}`}
                  >
                    {link?.name}
                  </Nav.Link>
                )
              })}


              {
                isAuthenticated && <Nav.Link
                  className="nav-links-styles"
                  onClick={logout}
                  style={{
                    color: "#dc3545",
                  }}
                  href={"#logout"}
                >
                  LOG OUT
                </Nav.Link>
              }
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar >

    </>
  )
}