import { Container, Row, Col } from 'react-bootstrap';
import StatCard from './components/StatCard';
import statImg1 from '../../assets/statistics/unemployment-1.svg';
import statImg2 from '../../assets/statistics/unemployment-2.svg';
import statImg3 from '../../assets/statistics/unemployment-3.svg';




export default function Statistics() {
  return (
    <>
      <section
        id="statistics"
        className="section statistics-content-section"
      >

        <Container>
          <Row>

            <Col
              xs={{ span: 12, order: 1 }}
              md={{ span: 6, order: 1 }}
              lg={{ span: 4, order: 1 }}
            >
              <StatCard
                statImg={statImg1}
                statText="In 2022, approximately 12.7 percent of African youth were unemployed."
              />
            </Col>

            <Col
              xs={{ span: 12, order: 1 }}
              md={{ span: 6, order: 1 }}
              lg={{ span: 4, order: 1 }}
            >
              <StatCard
                statImg={statImg2}
                statText="By 2025, 263 million young Africans will lack an economic stake in the system."
              />
            </Col>

            <Col
              xs={{ span: 12, order: 1 }}
              md={{ span: 6, order: 1 }}
              lg={{ span: 4, order: 1 }}
            >
              <StatCard
                statImg={statImg3}
                statText="Africa's youth population will rapidly double to more than 830 million by 2050."
              />
            </Col>

          </Row>
        </Container>
      </section>

    </>
  )
}