import styled from 'styled-components';
import SubTitle from '../../../../../utils/subtitle/SubTitle';
import { Colors } from '../../../../../utils/colors/colors';
import { Link } from 'react-router-dom';
import { useExport } from '../../../../../hooks/useExport';
import { useCreatedAt } from '../../../../../hooks/useCreatedAt';
import { useTimeToRead } from '../../../../../hooks/useTimeToRead';
import authService from '../../../../../services/auth.service';
import useAnalyticsEventTracker from '../../../../../hooks/useAnalyticsEventTracker';
import ReadMore from "../../../../utils/read-more/ReadMore";










export default function CardContent({
  blogTitle,
  blogpostImage,
  date,
  id,
  blogBody,
  slug
}) {

  const gaEventTracker = useAnalyticsEventTracker('Blog cards');
  const isAuthenticated = authService.isAuthenticated();

  const {
    fourthColor,
    secondaryColor
  } = Colors();


  const { editIcon } = useExport();

  return (
    <CardComponent>

      <a
        hrefLang="en-us"
        href={`/${slug}/${id}`}
      >
        <BlogImage
          style={{
            backgroundImage: `url(${blogpostImage})`,
          }}
        >

        </BlogImage>
      </a>

      <BlogContent>

        <a
          hrefLang="en-us"
          href={`/${slug}/${id}`}
          onClick={() => gaEventTracker(`${blogTitle}`)}
        >
          <SubTitle
            cName="clamped-text"
            textColor={fourthColor}
            text={blogTitle}
            textAlign="left"
            style={{
              fontWeight: "bold",
              margin: "0"
            }}
          />
        </a>

        <Wrapper>
          <DateAndTime
            style={{
              color: fourthColor
            }}
          >

            <Separator>
              <Div>
                {useCreatedAt(date)} &middot; {useTimeToRead(blogBody)} min read
              </Div>

              <Div>
                {isAuthenticated &&
                  <Link
                    to={`/admin/edit/${slug}/${id}`}
                    style={{
                      color: secondaryColor,
                      textDecoration: 'none'
                    }}
                  >
                    Edit

                    <Img
                      src={editIcon}
                      alt="Edit"
                      style={{
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  </Link>
                }
              </Div>

            </Separator>
          </DateAndTime>

          <a
            hrefLang="en-us"
            href={`/${slug}/${id}`}
          >
            <ReadMore />
          </a>
        </Wrapper>
      </BlogContent>


    </CardComponent>
  )
};




const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;


const CardComponent = styled.div`
    background-position: bottom right;
    background-repeat: no-repeat;
    padding: 0;
    display: block;
    border: 1px solid #eee;
    border-radius: 6px;
    min-height: 415px;
    height: 415px;
    margin: 1rem auto;
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1);
`;

const BlogImage = styled.div`
  min-height: 200px;
  max-height: 200px;
  min-width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const BlogContent = styled.div`
  min-height: 150px;
  max-height: 150px;
  min-width: 100%;
  padding: 1rem;
`;

const DateAndTime = styled.div`
    font-size: small;
    font-weight: 500;
    margin-top: 1rem;
`;

const Separator = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Div = styled.div`

`;

const Img = styled.img`
  width: 50px;
  height: 50px;
  object-fit: cover;
  object-position: center;
`;
