import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';

const HrContainer = styled.div`
	display: flex;
	flex-direction: row;
	margin: 0 auto 1rem;
	justify-content: center;
	align-items: center;
`;

const Line = styled.div`
	width: 80px;
	max-width: 80px;
	height: 3px;
	background-color: #f1a77d;
`;

const TextContainer = styled.div`
	background-color: transparent;
	margin: auto 1rem;
	font-size: 16px;

		@media (min-width: 768px) {
			font-size: 18px;
		}

		@media (min-width: 1024px) {
			font-size: 20px;
		}

		@media (min-width: 1440px) {
			font-size: 24px;
		}
`;


export const HorizontalRule = ({ text, textColor, style }) => {

  return (
    <Row>
      <Col>
        <HrContainer
          data-aos="fade-up"
          data-aos-once="true"
          style={style}
        >

          <Line></Line>

          <TextContainer
            style={{ color: textColor }}
          >
            {text}
          </TextContainer>

          <Line></Line>

        </HrContainer>
      </Col>
    </Row>

  );
}