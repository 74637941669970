import React from 'react';
import CreateNewPassword from '../../../components/auth/create-new-password';

function CreateNewPasswordPage() {
  return (

    <>
      <CreateNewPassword />
    </>
  );
}

export default CreateNewPasswordPage;