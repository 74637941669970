import styled from 'styled-components';
import { Colors } from '../../utils/colors/colors';
import Title from '../../utils/title/Title';



const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 3rem auto 0;
`;

const P = styled.p`
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 1rem;
`;

export default function AuthCard({ actionText, actionSubText, children }) {

  const {
    primaryColor,
    lightGrayColor
  } = Colors();


  return (
    <Container>
      <Title
        textColor={primaryColor}
        text={actionText}
        textAlign="center"
        style={{}}
      />
      <P
        style={{
          color: lightGrayColor
        }}
      >
        {actionSubText}
      </P>

      {children}
    </Container>
  );
}