import './CreateAdminOverlay.css';
import CreateAdminForm from "./components/CreateAdminForm";



export default function CreateAdminOverlay({
  showOverlay,
  setShowOverlay
}) {


  return (
    <>
      {
        showOverlay &&
        <div
          className="overlay"
        >
          <CreateAdminForm
            setShowOverlay={setShowOverlay}
            showOverlay={showOverlay}
          />
        </div>
      }
    </>
  )
}