import { useEffect, useState } from "react";
import styled from 'styled-components';
import { Colors } from '../../../utils/colors/colors';
import Title from '../../../utils/title/Title';
import { Link } from "react-router-dom";



const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-direction: center;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const P = styled.p`
  text-decoration: none;
  display: block;
  margin-top: 2rem;
`;

const PasswordResetSuccessful = () => {

  const {
    primaryColor,
    secondaryColor
  } = Colors();

  const [title, setTitle] = useState("Password Reset Successful | Eraconneckt");

  useEffect(() => {
    document.title = title;
    setTitle(title);
  }, [title]);


  return (
    <Container>

      <Title
        textColor={primaryColor}
        text="Your Password Has Been Reset"
        textAlign="center"
        style={{}}
      />

      <Link
        to="/auth/sign-in"
        style={{
          textDecoration: "none"
        }}
      >
        <P
          style={{
            color: secondaryColor,
            textDecoration: "none"
          }}
        >
          Sign in now
        </P>
      </Link>

    </Container>
  );
}


export default PasswordResetSuccessful;