import axios from "axios";
import { constants, roles, userStatus } from "../constants/constants";
import { toast } from "react-toastify";
import authHeader from "./auth-header";
import { CMSAdminRedirection, HRAdminRedirection, adminRedirection, inactiveUserAction } from "../reducers";
import { AUTH, FORGOT_PASSWORD, LOG_IN, RESET_PASSWORD } from "../routes";





export const API_URL = constants.API_URL;

const headers = authHeader();

const signup = async (email, password, role) => {
  console.log(role);

  const response = await axios
    .post(API_URL + AUTH,
      {
        "email": email,
        "password": password,
        "role": role,
      },
      {
        "headers": headers
      }
    );

  if (response?.data?.data?.token) {

    toast.success("Account successfully created.");
  }
  return response?.data?.data;
};

const login = async (email, password) => {
  const response = await axios
    .post(API_URL + LOG_IN,
      {
        "email": email,
        "password": password,
      }
    );

  // User is set to Inactive by admin
  if (response?.data?.data?.user?.status === userStatus.INACTIVE) {
    toast.warning("Your account is disabled. Please contact admin.");
    inactiveUserAction();
  } else if (response?.data?.data?.token && response?.data?.data?.user?.status === userStatus.ACTIVE) {
    localStorage.setItem("user", JSON.stringify(response?.data?.data?.user));
    localStorage.setItem("token", JSON.stringify(response?.data?.data?.token));

    localStorage.setItem("isAuthenticated", "true");
    toast.success("Logged in successful");
  };


  if (response?.data?.data?.user?.role === roles.ADMIN && response?.data?.data?.user?.status === userStatus.ACTIVE) {
    adminRedirection();
  } else if (response?.data?.data?.user?.role === roles.CMS_ADMIN && response?.data?.data?.user?.status === userStatus.ACTIVE) {
    CMSAdminRedirection();
  } else if (response?.data?.data?.user?.role === roles.HR_ADMIN && response?.data?.data?.user?.status === userStatus.ACTIVE) {
    HRAdminRedirection();
  } else {

    //  user is ACTIVE but role is not supported
    toast.error("Something went wrong. User role is unsupported.");
  }

};

const resetPassword = async (password, userId, authToken) => {

  const response = await axios
    .post(`${API_URL}${RESET_PASSWORD}/${userId}/${authToken}`,
      {
        "password": password,
      },
    );

  if (response.status === 200 || response.readyState
    === 4) {
    toast.success("Password reset successfully!");
  } else {
    toast.error("Something went wrong!");
  }
};

const forgotPassword = async (email) => {
  const response = await axios
    .post(API_URL + FORGOT_PASSWORD,
      {
        "email": email,
      },
      {
        "headers": headers
      }
    );

  if (response.status === 200 || response.readyState
    === 4) {
    toast.success("Email sent successfully!");

    // Clear localStorage details
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("isAuthenticated");
  } else {
    toast.error("Something went wrong!");
  }
};

const isAuthenticated = () => {
  return JSON.parse(localStorage.getItem("isAuthenticated"));
};

const currentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};


const logout = async () => {

  localStorage.removeItem("user");
  localStorage.removeItem("isAuthenticated");
  localStorage.removeItem("token");
  window.location.href = AUTH;
  toast.success("Logged out successful");
};

const authService = {
  signup,
  login,
  logout,
  resetPassword,
  forgotPassword,
  isAuthenticated,
  currentUser
};

export default authService;