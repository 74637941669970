import { Col } from 'react-bootstrap';

export default function FooterCol({ component }) {
	return (
		<>
			<Col
				xs={{ span: 12 }}
				md={{ span: 6 }}
				lg={{ span: 3 }}
			>
				{component}

			</Col>
		</>
	)
}