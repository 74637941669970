import React, { useState } from 'react';
import styled from 'styled-components';
// import videoBg from '../../../assets/img/hand.png';
import imageBg from '../../../assets/img/imageBg.png';
import ButtonComponent from '../../../utils/button/ButtonComponent';
import PlayIcon from '../../../utils/button/icon/PlayIcon';
import VideoOverlay from './VideoOverlay';





/*
* Video is currently not ready as at the time of making this update.
* When the video is ready, please make the appropriate updates.
* These updates include re-enabling the video-play button and
* Updating the `VideoOverlay` component with the correct video URL
*/

const VideoContainer = styled.div`
	background-color: transparent;
	${'' /* background-image: url(${videoBg}); */}
	background-image: url(${imageBg});
	background-size: contain;
	background-repeat: no-repeat;
	aspect-ratio: 1 / 1;
	margin: auto;
	margin-bottom: -3rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 1rem;

	@media (min-width: 768px) {
			padding: 2rem;
		}

	@media (min-width: 1024px) {
			margin-left: 3rem;
			margin-bottom: 0;
		}
`;

const ButtonComponentStyles = styled.div`

	@media (min-width: 768px) and (max-width: 800px) {
		margin-top: -10rem;
	}
`;

const Video = () => {

  const [showVideo, setShowVideo] = useState(false);
  const [showVideoBtn, setShowVideoBtn] = useState(false);

  const secondaryColor = "#eb8145";
  const text = `
		Watch Our Presentation
	`;

  const handleClick = () => {
    setShowVideo(true);
  };

  return (
    <>
      <VideoContainer
        data-aos-once="true"
        className="video-content-child"
      >
        {!showVideo && showVideoBtn &&
          <ButtonComponentStyles>
            <ButtonComponent
              bgColor={secondaryColor}
              text={text}
              style={{}}
              playIcon={<PlayIcon />}
              click={handleClick}
            />
          </ButtonComponentStyles>
        }

        {showVideo && <VideoOverlay
          showVideo={showVideo}
          setShowVideo={setShowVideo}
        />
        }

      </VideoContainer>
    </>
  );
}

export default Video;