import PostComponent from "../components/PostComponents";




export default function WriteBlog() {

  return (
    <>
      <PostComponent />
    </>
  )
}