import AuthCard from '../../AuthCard';
import FormComponent from './FormComponent';


const Right = () => {
  return (
    <AuthCard
      actionText="Welcome Back"
      actionSubText="Sign in to your admin account"
    >
      <FormComponent>

      </FormComponent>
    </AuthCard>
  );
}

export default Right;