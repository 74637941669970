import { constants } from "../constants/constants";
import { useLocationPathName } from "./useLocationPathname";



export const useSurveyDetails = () => {
  const {
    surveyType
  } = useLocationPathName();

  const {
    VOLUNTEER,
    PARTNERSHIP,
    ATTENDEE,
    USER_INTERVIEW_QUESTIONNAIRE,
    ALUMNI_SUCCESS_TRACKER,
    APPLICANT_QUESTIONNAIRE
  } = constants;


  let surveyActionText;
  let surveyActionSubText;
  let surveyFormTitle;
  let surveyFormSrc;


  switch (surveyType) {
    case ATTENDEE:
      surveyActionText = "Attendee Survey";
      surveyActionSubText = "Please fill out this survey form";
      surveyFormTitle = "Eraconneckt attendee survey form";
      surveyFormSrc = "https://forms.office.com/Pages/ResponsePage.aspx?id=9_D9R9N8HEKj_htcrwWr8iQRxswqzoNBhOtj2zKk8wBUMVZHRTA0NU9PSExLSzVCN1pDWTFNRjI0Ui4u&embed=true%22";
      break;

    case VOLUNTEER:
      surveyActionText = "Volunteer Form";
      surveyActionSubText = "Please fill out this volunteer form";
      surveyFormTitle = "Volunteer at Eraconneckt";
      surveyFormSrc = "https://forms.office.com/Pages/ResponsePage.aspx?id=9_D9R9N8HEKj_htcrwWr8vZ2AEX5lTFCvRh6F_4irUdURVVSWEdYUllCVjY2TVhHQzgxTlIxMVFXOS4u";
      break;

    case PARTNERSHIP:
      surveyActionText = "Partnership Form";
      surveyActionSubText = "Please fill out this partnership form";
      surveyFormTitle = "Partner with Eraconneckt";
      surveyFormSrc = "https://forms.office.com/pages/responsepage.aspx?id=9_D9R9N8HEKj_htcrwWr8vZ2AEX5lTFCvRh6F_4irUdUQzBYR1cxTTRKUlBHU0NKNjMwSUpPRUNGVC4u";
      break;

    case ALUMNI_SUCCESS_TRACKER:
      surveyActionText = "Eraconneckt WRP Alumni Success Tracker";
      surveyActionSubText = "Please fill out this survey form";
      surveyFormTitle = "Eraconneckt WRP Alumni Success Tracker";
      surveyFormSrc = "https://forms.office.com/pages/responsepage.aspx?id=9_D9R9N8HEKj_htcrwWr8vZ2AEX5lTFCvRh6F_4irUdUOVIyV0xLSklPODZPME4xUzJNNEpZMlROQi4u";
      break;

    case APPLICANT_QUESTIONNAIRE:
      surveyActionText = "EraConneckt Applicant Questionnaire";
      surveyActionSubText = "Please fill out this survey form";
      surveyFormTitle = "EraConneckt Applicant Questionnaire";
      surveyFormSrc = "https://forms.office.com/pages/responsepage.aspx?id=9_D9R9N8HEKj_htcrwWr8laceFU6RKpIjuOh8YDIqq1UMEdQRlY3SENCSldYVFZBVDFGUFA0STNPOS4u";
      break;

    case USER_INTERVIEW_QUESTIONNAIRE:
      surveyActionText = "Eraconneckt User Interview Questionnaire";
      surveyActionSubText = "Please fill out this survey form";
      surveyFormTitle = "Eraconneckt User Interview Questionnaire";
      surveyFormSrc = "https://forms.office.com/Pages/ResponsePage.aspx?id=9_D9R9N8HEKj_htcrwWr8vZ2AEX5lTFCvRh6F_4irUdUNkM0TEVNODNXNzUyQUNOUzlHVTkxTTYzMi4u";
      break;

    default:
      surveyActionText = "Attendee Survey";
      surveyActionSubText = "Please fill out this survey form";
      surveyFormTitle = "Eraconneckt attendee survey form";
      surveyFormSrc = "https://forms.office.com/Pages/ResponsePage.aspx?id=9_D9R9N8HEKj_htcrwWr8iQRxswqzoNBhOtj2zKk8wBUMVZHRTA0NU9PSExLSzVCN1pDWTFNRjI0Ui4u&embed=true%22";
      break;
  }


  return {
    surveyActionText,
    surveyActionSubText,
    surveyFormTitle,
    surveyFormSrc,
    surveyType
  }
}