import React from 'react';
import styled from 'styled-components';
import './Paragraph.css';

const ParagraphComponent = styled.div`
		display: block;
		margin-top: 12px;
`;

function Paragraph({ textColor, text, textAlign, style }) {


  return (
    <ParagraphComponent
      data-aos="fade-up"
      data-aos-once="true"
      className="paragraph"
      style={{
        color: textColor,
        textAlign: textAlign,
        ...style
      }}
    >
      {text}
    </ParagraphComponent>
  );
}

export default Paragraph;