import { useSurveyDetails } from "../../../../hooks/useSurveyType";
import AuthCard from "../../../auth/AuthCard";
import FormComponent from './FormComponent';


const Right = () => {

  const {
    surveyActionText,
    surveyActionSubText,
    surveyFormTitle,
    surveyFormSrc,
  } = useSurveyDetails();


  return (
    <AuthCard
      actionText={surveyActionText}
      actionSubText={surveyActionSubText}
    >
      <FormComponent
        formTitle={surveyFormTitle}
        formSrc={surveyFormSrc}
      >
      </FormComponent>
    </AuthCard>
  );
}

export default Right;