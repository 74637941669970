import React, { useEffect, useState } from 'react'
import { constants } from '../../../../constants/constants';
import { useFetch } from '../../../../hooks/useFetch';
import { Fragment } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import Loader from '../../../../utils/loader/Loader';
import PageNavigation from '../../../../utils/page-navigation/PageNavigation';
import JobCard from './components/JobCard';













export default function JobsCards() {





  const API_URL = constants.API_URL;
  const NUMBER_OF_JOB_CARD_PER_PAGE = 8;
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const {
    isPending, error, data
  } = useFetch(`${API_URL}/jobs`, {});

  if (error) {
    console.error(error);
  }

  const items = data?.data;

  // Invoked when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset =
      (event?.selected * NUMBER_OF_JOB_CARD_PER_PAGE) % items?.length;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    const endOffset = itemOffset + NUMBER_OF_JOB_CARD_PER_PAGE;
    setCurrentItems(items?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items?.length / NUMBER_OF_JOB_CARD_PER_PAGE));
  }, [NUMBER_OF_JOB_CARD_PER_PAGE, itemOffset, items]);



  return (
    <>
      <>
        <section
          className="section blog-cards-section"
          id="job-cards"
          style={{
            position: "relative",
            top: "-25vh"
          }}
        >

          <Container>
            <Row className="mt-5">
              <Loader error={error} isPending={isPending} />

              {!isPending && currentItems && (
                <Fragment>
                  {currentItems?.map((job, index) => (

                    <Col
                      xs={{ span: 12 }}
                      md={{ span: 4 }}
                      lg={{ span: 3 }}
                      key={job?.id}
                    >
                      <JobCard
                        position={job?.position}
                        level={job?.level}
                        description={job?.description}
                        location={job?.location}
                        id={job?.id}
                        jobType={job?.jobType}
                        slug={"job"}
                        date={job?.createdAt}
                      />

                    </Col>
                  ))}
                  <PageNavigation
                    isPending={isPending}
                    error={error}
                    data={currentItems ?? currentItems}
                    pageCount={pageCount}
                    handlePageClick={handlePageClick}
                  />
                </Fragment>
              )}
            </Row>

          </Container>
        </section>
      </>
    </>
  )
}
