import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import './Footer.css';
import useDate from '../../hooks/useDate';
import FooterCol from './FooterCol';
import FirstCol from './components/FirstCol';
import SecondCol from './components/SecondCol';
import ThirdCol from './components/ThirdCol';
import FourthCol from './components/FourthCol';

const FooterStyles = styled.div`
	background-color: #393939;
	color: #fff;
	padding: 2rem 0;
`;

const HR = styled.div`
	height: 1px;
	width: 100%;
	background-color: #adca96;
	border: 1px solid #adca96;
	margin-top: 12px;
`;

export default function Footer() {

	const { year } = useDate();

	return (
		<section
			id="footer"
			className="section footer-section"
		>
			<FooterStyles>
				<Container>

					<Row>
						<FooterCol
							component={<FirstCol />}
						/>
						<FooterCol
							component={<SecondCol />}
						/>
						<FooterCol
							component={<ThirdCol />}
						/>
						<FooterCol
							component={<FourthCol />}
						/>
					</Row>

					<Row>
						<HR
						/>
					</Row>

					<Row>
						<Col
							className="mt-3"
						>
							&copy;&nbsp;Eraconneckt {year}. All rights reserved.
						</Col>
					</Row>

				</Container>

			</FooterStyles>
		</section>
	)
}