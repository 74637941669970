import { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Text from './content/Text';
import Image from './content/Image';

export default function Time({ position, timeImg, timelineHeading, timelineText, timelineTextTwo, rightText }) {

	const zeroSpacing = {
		margin: 0,
		padding: 0,
	};

	// eslint-disable-next-line no-restricted-globals
	var deviceWidth = screen.width;

	const [isSmallScreen, setIsSmallScreen] = useState(false);

	useEffect(() => {
		if (deviceWidth > 992) {
			setIsSmallScreen(false);
		} else {
			setIsSmallScreen(true);
		}
	}, [isSmallScreen, deviceWidth]);


	return (
		<>
			<div
				data-aos={`${isSmallScreen ? `fade-up` : `fade-up-${position}`}`}
				data-aos-delay="400"
				data-aos-once="true"
				className={`timeline-container ${position}`}>
				<div className="content">


					{rightText ?
						(<>
							<Container
								style={zeroSpacing}
							>
								<Row
									style={zeroSpacing}
								>
									<Col
										style={zeroSpacing}

										xs={{ span: 12 }}
										md={{ span: 12 }}
										lg={{ span: 4 }}
									>
										<Image
											timeImg={timeImg}
											timelineHeading={timelineHeading}
										/>
									</Col>

									<Col
										style={zeroSpacing}

										xs={{ span: 12 }}
										md={{ span: 12 }}
										lg={{ span: 8 }}
									>
										<Text
											timelineHeading={timelineHeading}
											timelineText={timelineText}
											timelineTextTwo={timelineTextTwo}
											rightText={rightText}
										/>
									</Col>
								</Row>
							</Container>
						</>
						) :
						(
							<>
								<Container
									style={zeroSpacing}
								>
									<Row
										style={zeroSpacing}
									>
										<Col
											style={zeroSpacing}

											xs={{ span: 12, order: 2 }}
											md={{ span: 12, order: 1 }}
											lg={{ span: 8 }}
										>
											<Text
												timelineHeading={timelineHeading}
												timelineText={timelineText}
												timelineTextTwo={timelineTextTwo}
												rightText={rightText}
											/>
										</Col>

										<Col
											xs={{ span: 12, order: 1 }}
											md={{ span: 12, order: 2 }}
											lg={{ span: 4 }}
										>
											<Image
												timeImg={timeImg}
												timelineHeading={timelineHeading}
											/>
										</Col>

									</Row>
								</Container>
							</>
						)}

				</div>
			</div>
		</>
	)
}