import React, { useState } from 'react';
import styled from 'styled-components';
import Title from '../../utils/subtitle/SubTitle';



const CardStyles = styled.div`
	height: 400px;
	min-height: 400px;
	max-width: 350px;
	background-color: #6e6e70;
	display: flex;
	justify-content: center;
	align-items: center;
	background-repeat: no-repeat;
	background-style: cover;
	transition: linear all 0.6s;

	:hover {
		transform: scale(1.1, 1.1);
	}
`;

const HoverCardStyles = styled.div`
	display: flex;
  height: 400px;
	min-height: 400px;
	max-width: 350px;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	background-color: #89b369;
	background-repeat: no-repeat;
	background-style: cover;
	transition: linear all 0.4s;
  margin: 1rem auto;
  padding-top: 1rem;
  padding-bottom: 1rem;

	:hover {
		${'' /* transform: scale(1.1, 1.1); */}
	}
`;

const HoverCardContentStyles = styled.div`
	text-align: justify;
	margin: 1rem;
	color: #fff;
`;

const HoverCardLinksStyles = styled.div`
	width: 100%;
	padding: 9px;
	background-color: #f4f8f0;
	color: #4f4f4f;
	text-transform: uppercase;
	opacity: 0.8;
	text-decoration: none;
	text-align: center;
	margin: 12px auto;
`;



function Card({
  cardText,
  cardBg,
  hoverText,
  link1,
  link2,
  linkText1,
  linkText2,
  isDonate,
  handleDonationOverlay,
  setWhoIsDonating
}) {

  const linkStyles = {
    textDecoration: "none",
    color: "#4f4f4f",
    fontWeight: "bold",
    width: "100%",
    cursor: "pointer",
  };

  const [isHovered, setIsHovered] = useState(false);


  const handleMakePayment = () => {
    console.log("Payment initiated");
  };

  const hoverCard = () => {
    setIsHovered(true);
  };

  const unHoverCard = () => {
    setIsHovered(false);
  };



  return (
    <>
      {!isHovered ?
        (
          <CardStyles
            onMouseEnter={hoverCard}
            onMouseLeave={unHoverCard}
            style={{ backgroundImage: `url(${cardBg})`, margin: "1rem auto" }}>
            <Title
              textColor="#fff"
              text={cardText}
              textAlign="center"
              style={{ fontWeight: "900", fontSize: "2.625rem" }}
            />
          </CardStyles>
        )
        :
        (<HoverCardStyles
          onMouseEnter={hoverCard}
          onMouseLeave={unHoverCard}
        >

          <Title
            textColor="#fff"
            text={cardText}
            textAlign="center"
            style={{
              fontWeight: "900",
              fontSize: "2.625rem",
              "marginTop": "1rem"
            }}
          />

          <HoverCardContentStyles
            data-aos="fade-up"
            data-aos-once="true"
          >
            {hoverText}
          </HoverCardContentStyles>

          <div
            style={{
              width: "100%",
            }}
          >
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              hrefLang="en-us"
              style={linkStyles}
              href={!isDonate ? `${link1}` : null}
              rel="noreferrer"
              onClick={(e) => { isDonate && handleDonationOverlay(); handleMakePayment(); setWhoIsDonating("individual"); }}
            >
              <HoverCardLinksStyles>
                {linkText1}
              </HoverCardLinksStyles>
            </a>

            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              hrefLang="en-us"
              style={linkStyles}
              href={!isDonate ? `${link2}` : null}
              rel="noreferrer"
              onClick={(e) => {
                isDonate &&
                  handleDonationOverlay();
                handleMakePayment();
                isDonate && setWhoIsDonating("organization");
              }}
            >
              {linkText2 && <HoverCardLinksStyles>
                {linkText2}
              </HoverCardLinksStyles>}
            </a>
          </div>

        </HoverCardStyles>

        )
      }
    </>
  );
}

export default Card;