import { Fragment, useState, useEffect } from "react";
import Footer from '../../components/footer';
import BlogsCard from '../../../src/components/blog/components/blogs-cards';
import LatestBlog from '../../../src/components/blog/components/latest-blog';
import { useFetch } from '../../hooks/useFetch';
import Loader from "../../utils/loader/Loader";
import { constants } from "../../constants/constants";
import BlogHero from "../../components/blog-hero/BlogHero";



export default function PostIndex({ slug }) {

  // This components is the component for the blog home
  const word = slug;
  const firstLetter = word.charAt(0);
  const firstLetterCap = firstLetter.toUpperCase();
  const remainingLetters = word.slice(1);
  const capitalizedWord = firstLetterCap + remainingLetters;



  const [title, setTitle] = useState(`Eraconneckt | ${capitalizedWord}`);

  const [latestBlog, setLatestBlog] = useState({});

  const URL = constants.API_URL;

  const {
    isPending,
    error,
    data
  } = useFetch(`${URL}/${slug}s`, {});

  if (error) {
    console.error(error);
  }



  useEffect(() => {

    if (!isPending) {
      var blogs = data.data;
      var singleLatestBlog = blogs[Object.keys(blogs)[0]];
      // console.log(singleLatestBlog);
      setLatestBlog(singleLatestBlog);

      setTitle(`Eraconneckt | ${slug}`);

    }
  }, [data.data, isPending, slug]);


  useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    document.title = title;
  }, [title]);



  return (
    <>

      <BlogHero
        slug={slug}
      />

      {/* Latest Blog */}


      <>
        {isPending ? (<Fragment><Loader /> </Fragment>) :
          <Fragment>

            <a
              href={`/${slug}/${latestBlog?.id}`}
              key={latestBlog?.id}
              hrefLang="en-us"
            >
              <LatestBlog
                id={latestBlog?.id}
                key={latestBlog?.id}
                latestBlogId={latestBlog?.id}
                latestBlogBg={latestBlog?.featuredImage}
                latestBlogTitle={latestBlog?.blogTitle}
                latestBlogBody={latestBlog?.blogBody}
                date={latestBlog?.createdAt}
                updated={latestBlog?.updatedAt}
                timeToRead={latestBlog?.timeToRead}
              />

            </a>
          </Fragment>

        }

      </>



      <BlogsCard
        slug={slug}
      />
      <Footer />
    </>
  )
}