import React from 'react'
import { Card, ListGroup, Button } from 'react-bootstrap';
import { useCreatedAt } from '../../../../../hooks/useCreatedAt';
import { Colors } from '../../../../../utils/colors/colors';
import { truncate } from '../../../../../utils/truncate/truncate';
import postService from '../../../../../services/post.service';
import { toast } from "react-toastify";
import DOMPurify from 'dompurify';








export default function JobCard({
  position,
  level,
  description,
  location,
  jobType,
  date,
  id
}) {


  const handleDeleteJob = () => {

    // eslint-disable-next-line no-restricted-globals
    const confirmation = confirm(`Are you sure you want to delete?`);

    if (confirmation === true) {
      toast.info(`Deleting job post`)

      postService.deleteJob(id).then(() => {
        toast.success(`Job deleted successfully.`);
        window.location.reload();
      })
        .catch((error) => {
          console.error(error)
          toast.error(`Did not delete`)
        })
        .finally(() => {
        })
    } else {
      console.info("Delete aborted");
      toast.info("Delete aborted");
    }
  }


  const {
    whiteColor,
  } = Colors();

  return (
    <>
      <Card
        style={{
          margin: "12px auto",
          height: "350px",
          minHeight: "350px",
          maxHeight: "350px",
        }}
      >
        <Card.Body>
          <Card.Title>
            <b>
              {position}
            </b>
            -
            <i> {location}
            </i>
          </Card.Title>
          <Card.Text>{level}{" "}-{" "}{jobType}{" "}-
            <i>
              {useCreatedAt(date)}
            </i>
          </Card.Text>


          <ListGroup variant="flush">
            <ListGroup.Item
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(truncate(description, 150)) }}
            >
            </ListGroup.Item>
          </ListGroup>
        </Card.Body>

        <Card.Footer
          style={{
            backgroundColor: "transparent"
          }}
        >

          <ListGroup
            variant="flush"
            as="ul"
            className="d-flex justify-content-between align-items-start"
          >
            <ListGroup.Item
            >
              <Button
                variant="primary"
                href={`/admin/edit/job/${id}`}
                style={{
                  marginRight: "8px",
                  color: whiteColor
                }}
              >
                Edit
              </Button>


              <Button
                variant="danger"
                onClick={handleDeleteJob}
                style={{
                  marginRight: "8px",
                  color: whiteColor
                }}
              >
                Delete
              </Button>
            </ListGroup.Item>
          </ListGroup>

        </Card.Footer>

      </Card >
    </>
  )
}
