import styled from 'styled-components';
import { Colors } from '../../../../src/utils/colors/colors';
import { useExport } from '../../../hooks/useExport';
import CreateAdminOverlay from "./CreateAdminOverlay/index";





const {
  primaryColor,
  whiteColor
} = Colors();


const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  width: 100%;
  margin: 1rem auto;
  flex-wrap: wrap;
`;

const Div = styled.div`
  display: flex;
  justify-content: space-between;
  height: 40px;
  margin: 0 1.425rem;
`;

const Title = styled.span`
  color: ${primaryColor};
  font-weight: 700;
`;

const Container = styled.div`
  min-width: 150px;
  max-width: 180px;
  border-radius: 4px;
  padding: 0 8px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const InputContainer = styled(Container)`
  border: 0.5px solid #a6a6a6;
  margin-right: 8px;
`;

const ButtonContainer = styled(Container)`
  background-color: ${primaryColor};
  margin-left: 8px;
  cursor: pointer;
`;

const Input = styled.input`
  border: none;
  outline: none;
  height: 99%;
  width: 99%;
  font-weight: 400;
  font-size: 14px;
  display: inline-block;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: #b9b9b9;
  }
  :-ms-input-placeholder {
     color: #b9b9b9;
  }
`;

const IconContainer = styled.div`
  width: 18px;
  cursor: pointer;
`;

const Img = styled.img`
  height: 100%;
  width: 100%;
`;





const Text = styled.span`
  color: ${whiteColor};
`


export default function TableContent({
  handleSearchString,
  handleSearch,
  handleAddAdmin,
  showOverlay,
  setShowOverlay,
  searchString,
  handleEnterKey
}) {




  const {
    searchIcon,
    plusIcon,
  } = useExport();

  return (
    <>
      <Header>
        <Div>
          <Title>
            Admin
          </Title>
        </Div>


        {/* import useExport */}
        <Div>
          <InputContainer>
            <Input
              placeholder="Search by email"
              name="search"
              type="search"
              onKeyDown={handleEnterKey}
              onChange={handleSearchString}
              value={searchString}
            />

            <IconContainer
              onClick={handleSearch}
            >
              <Img
                src={searchIcon}
                alt="Search"
                title="Search"
              />
            </IconContainer>
          </InputContainer>

          <ButtonContainer
            onClick={handleAddAdmin}
          >
            <IconContainer
            >
              <Img
                src={plusIcon}
                alt="Add"
                title="Add new admin"
              />
            </IconContainer>

            <Text>
              Add new admin
            </Text>
          </ButtonContainer>
        </Div>
      </Header>

      {
        showOverlay &&
        <CreateAdminOverlay
          setShowOverlay={setShowOverlay}
          showOverlay={showOverlay}
        />
      }

    </>
  )
}