import { roles } from "../../../constants/constants";
import analyticsEventTracker from "../../../hooks/useAnalyticsEventTracker";
import { ADMIN, ADMIN_BLOG, ADMIN_DRAFT, ADMIN_JOB, BLOG, HOME, ADMIN_CREATE_JOB } from "../../../routes";
import authService from "../../../services/auth.service";




const gaEventTracker = analyticsEventTracker('Nav menu buttons');

const isAuthenticated = authService.isAuthenticated();
const currentUser = authService.currentUser();


const publicLinks = [
  {
    id: 1,
    name: "HOME",
    url: HOME,
    function: gaEventTracker("HOME"),
  },
  {
    id: 2,
    name: "ABOUT US",
    url: "#about",
    function: gaEventTracker("ABOUT US"),
  },
  {
    id: 3,
    name: "WORK WITH US",
    url: "#work",
    function: gaEventTracker("WORK WITH US"),
  },
  {
    id: 4,
    name: "BLOGS",
    url: BLOG,
    function: gaEventTracker("BLOGS"),
  },
  {
    id: 5,
    name: "CONTACT",
    url: "#contact",
    function: gaEventTracker("CONTACT"),
  },
];



const HRAdminLinks = [
  {
    id: 8,
    name: "ALL JOBS",
    url: ADMIN_JOB,
    function: gaEventTracker("ALL JOBS"),
  },
  {
    id: 10,
    name: "POST NEW JOBS",
    url: ADMIN_CREATE_JOB,
    function: gaEventTracker("POST NEW JOBS"),
  },
]

const CMSAdminLinks = [
  {
    id: 6,
    name: "BLOGS",
    url: ADMIN_BLOG,
    function: gaEventTracker("BLOG"),
  },
  {
    id: 7,
    name: "DRAFTS",
    url: ADMIN_DRAFT,
    function: gaEventTracker("DRAFTS"),
  },
];


const adminLinks = [
  ...CMSAdminLinks,
  ...HRAdminLinks,
  {
    id: 9,
    name: "CREATE ADMIN",
    url: ADMIN,
    function: gaEventTracker("CREATE ADMIN"),
  },
];


export const navLinks = !isAuthenticated ? publicLinks
  : isAuthenticated ?
    currentUser?.role === roles.HR_ADMIN ? HRAdminLinks
      : currentUser?.role === roles.CMS_ADMIN ? CMSAdminLinks
        : adminLinks
    : publicLinks;