import Time from './Time';
import ideationImg from '../../assets/timeline/2016.svg';
import brainstormingImg from '../../assets/timeline/2017.svg';
import researchImg from '../../assets/timeline/2018.svg';
import openForumImg from '../../assets/timeline/2019.svg';
import launchImg from '../../assets/timeline/2020.svg';
// import kenyaImg from '../../assets/timeline/2021.svg';

export default function Period() {

  return (
    <>
      <div className="timeline-content">

        <Time
          timeImg={ideationImg}
          position="right"
          rightText={true}
          timelineHeading="2016 - Ideation Phase"
          timelineText="The necessity of identifying and resolving the African countries' unemployment problems was realized. "
          timelineTextTwo=""
        />

        <Time
          timeImg={brainstormingImg}
          position="left"
          rightText={false}
          timelineHeading="2017 - Brainstorming Phase"
          timelineText="The functionality and visual appearance of the employment portal were developed using the best technologies."
          timelineTextTwo=""
        />

        <Time
          timeImg={researchImg}
          position="right"
          rightText={true}
          timelineHeading="2018 - Research Phase"
          timelineText="Interviews with recruiters and job seekers were conducted to better understand their objectives and pain points."
          timelineTextTwo=""
        />

        <Time
          timeImg={openForumImg}
          position="left"
          rightText={false}
          timelineHeading="2019 - 1st Open Forum (Nigeria)"
          timelineText="April - The Job Portal 1.0 was deployed and tested."
          timelineTextTwo="December - The inaugural Nigeria Workforce Readiness Program (WFR) event took place. During the WFR event, participants received help updating their resumes, locating career paths, and developing prospects to meet recruiter requirements. Numerous incentives and resources, including computers, were made available to help job seekers in their new professions."
        />

        <Time
          timeImg={launchImg}
          position="right"
          rightText={true}
          timelineHeading="2020 - Launch Phase"
          timelineText="May - The job portal EraConneckt.com was launched."
          timelineTextTwo="June - The hiring procedure started. Applicants were hired to work from home in jobs including design thinking, research, and social media management."
        />

        {/* <Time
          timeImg={kenyaImg}
          position="left"
          rightText={false}
          timelineHeading="2021 - Kenya Discovery Phase"
          timelineText="The Workforce Readiness (WFR) event in Kenya examined the issue of unemployment and offered job seekers solutions."
          timelineTextTwo=""
        /> */}

      </div>
    </>
  )
}