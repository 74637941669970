import React, { Component } from "react";
import { createGlobalStyle } from "styled-components";
import { Routes, Route } from "react-router-dom";
import AdminPanelPage from "../pages/admin/admin-panel";
import Home from "../pages/home";
import SurveyPage from "../pages/survey";
import NotFound from "./notfound/NotFound";
import SignInPage from "../pages/auth/sign-in-page";
import SignUpPage from "../pages/auth/sign-up-page";
import ResetPasswordPage from "../pages/auth/reset-password-page";
import CreateNewPasswordPage from "../pages/auth/create-new-password-page";
import PasswordResetSuccessful from "../components/auth/password-reset-successful";
import ResetLinkSent from "../components/auth/reset-link-sent";
import Blog from "../pages/blog";
import Draft from "../pages/draft";
import SingleBlog from "../components/blog/components/single-blog";
import AdminBlogComponent from "../pages/admin/blog";
import AdminDraftComponent from "../pages/admin/draft";
import WriteBlog from "../pages/admin/write-blog/index";
import EditBlog from "../pages/admin/edit-blog/index";
import AdminArchiveComponent from "../pages/admin/archive/index";
import { ToastContainer } from "react-toastify";
import { HRAdminRoute, CMSAdminRoute, ProtectedRoute, AdminRoute } from "./../routes/ProtectedRoute";
import DonatePage from "../pages/donate";
import JobPage, { CreateJob, EditJob } from "../pages/admin/job";
import { ADMIN, ADMIN_BLOG, ADMIN_DRAFT, ADMIN_JOB, AUTH, BLOG, DRAFT, RESET_PASSWORD, SIGN_IN, SIGN_UP, SINGLE_BLOG, ADMIN_ARCHIVED, WILD_CARD, ADMIN_WRITE, HOME, DONATION, SURVEY, RESET_LINK_SENT, PASSWORD_RESET_SUCCESSFUL, SINGLE_DRAFT, ADMIN_CREATE_JOB, CREATE_NEW_PASSWORD, ADMIN_EDIT_BLOG, ADMIN_EDIT_DRAFT, ADMIN_EDIT_JOB } from "../routes";
import { pageErrors } from "../constants/constants";









const SectionStyle = createGlobalStyle`
		section {
			max-width: 1920px;
			min-width: 100%;
		}
	`;

class Ui extends Component {

  render() {
    return (
      <>
        <SectionStyle />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />


        <Routes>

          {/* Public routes */}
          <Route
            path={SINGLE_BLOG}
            element={<SingleBlog />} />
          <Route
            path={BLOG}
            element={<Blog />} />

          <Route exact path={DRAFT} element={<CMSAdminRoute />}>
            <Route exact path={DRAFT} element={<Draft />} />
          </Route>

          <Route exact path={SINGLE_DRAFT} element={<CMSAdminRoute />}>
            <Route exact path={SINGLE_DRAFT} element={<SingleBlog />} />
          </Route>

          <Route
            path={AUTH}
            element={<SignInPage />} />

          <Route
            path={RESET_PASSWORD}
            element={<ResetPasswordPage />} />

          <Route
            path={`${CREATE_NEW_PASSWORD}/:userId/:authToken`}
            element={<CreateNewPasswordPage />} />

          <Route
            path={CREATE_NEW_PASSWORD}
            element={
              <NotFound
                error={pageErrors.INVALID_TOKEN}
              />
            }
          />

          <Route
            path={PASSWORD_RESET_SUCCESSFUL}
            element={<PasswordResetSuccessful />} />

          <Route
            path={RESET_LINK_SENT}
            element={<ResetLinkSent />} />

          <Route
            path={SIGN_IN}
            element={<SignInPage />} />

          {/* /Public routes */}


          <Route exact path={SIGN_UP} element={<AdminRoute />}>
            <Route exact path={SIGN_UP} element={<SignUpPage />} />
          </Route>

          <Route exact path={ADMIN} element={<AdminRoute />}>
            <Route exact path={ADMIN} element={<AdminPanelPage />} />
          </Route>

          <Route exact path={`${ADMIN}${HOME}`} element={<AdminRoute />}>
            <Route exact path={`${ADMIN}${HOME}`} element={<AdminPanelPage />} />
          </Route>

          {/* CMS Admin routes */}
          <Route exact path={ADMIN_BLOG} element={<CMSAdminRoute />}>
            <Route exact path={ADMIN_BLOG} element={<AdminBlogComponent />} />
          </Route>

          <Route exact path={ADMIN_DRAFT} element={<CMSAdminRoute />}>
            <Route exact path={ADMIN_DRAFT} element={<AdminDraftComponent />} />
          </Route>

          <Route exact path={ADMIN_ARCHIVED} element={<CMSAdminRoute />}>
            <Route exact path={ADMIN_ARCHIVED} element={<AdminArchiveComponent />} />
          </Route>

          <Route exact path={ADMIN_WRITE} element={<CMSAdminRoute />}>
            <Route exact path={ADMIN_WRITE} element={<WriteBlog />} />
          </Route>

          <Route exact path={ADMIN_EDIT_DRAFT} element={<CMSAdminRoute />}>
            <Route exact path={ADMIN_EDIT_DRAFT} element={<EditBlog />} />
          </Route>

          <Route exact path={ADMIN_EDIT_BLOG} element={<CMSAdminRoute />}>
            <Route exact path={ADMIN_EDIT_BLOG} element={<EditBlog />} />
          </Route>

          <Route exact path={`${ADMIN}${HOME}`} element={<CMSAdminRoute />}>
            <Route exact path={`${ADMIN}${HOME}`} element={<AdminBlogComponent />} />
          </Route>

          {/* /CMS Admin routes */}

          {/* HR Amin routes */}
          <Route exact path={ADMIN_JOB} element={<HRAdminRoute />}>
            <Route exact path={ADMIN_JOB} element={<JobPage />} />
          </Route>

          <Route exact path={ADMIN_CREATE_JOB} element={<HRAdminRoute />}>
            <Route exact path={ADMIN_CREATE_JOB} element={<CreateJob />} />
          </Route>

          <Route exact path={ADMIN_EDIT_JOB} element={<HRAdminRoute />}>
            <Route exact path={ADMIN_EDIT_JOB} element={<EditJob />} />
          </Route>
          {/* /HR Amin routes */}

          <Route exact path={`${ADMIN}/${WILD_CARD}`} element={<ProtectedRoute />}>
            <Route exact path={`${ADMIN}/${WILD_CARD}`} element={<AdminBlogComponent />} />
          </Route>

          <Route path={SURVEY} element={<SurveyPage />} />
          <Route path={DONATION} element={<DonatePage />} />
          <Route path={HOME} element={<Home />} />
          <Route path={WILD_CARD}
            element={
              <NotFound
                titleText={pageErrors.NOT_FOUND}
              />
            }
          />
        </Routes>
      </>
    );
  }
}

export default Ui;