import { FaRegEyeSlash, FaRegEye } from 'react-icons/fa';







export const Icons = () => {
  return (
    {
      FaRegEyeSlash,
      FaRegEye,
    }
  );
};