import React from 'react';
import styled from 'styled-components';
import heroBg from '../../assets/hero/hero.png';
import NavComponent from '../navbar';
import HeroContent from './hero-content/HeroContent';

const Section = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	background-color: #e2e2e2;
	background-image: url(${heroBg});
	background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`;

const Hero = () => {
	return (
		<Section>
			<section
				id="hero"
				className="section hero-section">
				<NavComponent
				/>
				<HeroContent />
			</section>

			{/* For smooth scrolling */}
			<div id="about"></div>
		</Section>
	)
}

export default Hero;