import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import ContactTitle from './ContactTitle';
import ContactForm from './ContactForm';
import contactBg from '../../assets/contact/map.svg';

const ContactSectionStyles = styled.div`
	background-color: rgb(137, 179, 105);
	background-image: url(${contactBg});
	background-repeat: no-repeat;
	background-size: cover;
	padding: 2rem;
  height: 650px;
`;

export default function Contact() {
  return (
    <>
      <section
        id="contact"
        className="section contact-content-section">

        <ContactTitle />

        <ContactSectionStyles>
          <Container>

            <Row
              style={{}}
            >
              <Col
                xs={{ span: 12, order: 2 }}
                md={{ span: 12, order: 1 }}
                lg={{ span: 6, order: 1 }}
              >

              </Col>

              <Col
                xs={{ span: 12, order: 1 }}
                md={{ span: 12, order: 2 }}
                lg={{ span: 6, order: 2 }}
                style={{ backgroundColor: "#fff" }}
                data-aos="fade-up"
                data-aos-once="true"
              >
                <ContactForm />

              </Col>
            </Row>

          </Container>
        </ContactSectionStyles>
      </section>
    </>
  )
}