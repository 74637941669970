import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { HorizontalRule } from '../../utils/horizontalrule/HorizontalRule';
import Period from './Period';
import downIcon from '../../assets/icons/down-button.svg';
import './Timeline.css';

const timelineBg = {};

const TimelineStyles = styled.div`
	background-color: #201f4f;
	background-image: url(${timelineBg});
	background-repeat: no-repeat;
	background-size: cover;
`;

const IconStyle = styled.div`
	display: flex;
	flex-direction: row;
	margin: 0 auto 1rem;
	justify-content: center;
	align-items: center;
`;

function Timeline() {
  return (
    <section
      className="section timeline"
      id="timeline">

      <TimelineStyles>

        <Container>

          <HorizontalRule
            text="Timeline"
            textColor="#fff"
            style={{ marginTop: "1rem" }}
          />

          <Row>
            <Col>
              <IconStyle>
                <img
                  data-aos="fade-up"
                  data-aos-once="true"
                  alt="down icon"
                  src={downIcon}
                  style={{ height: "2rem", width: "2rem" }}
                />
              </IconStyle>
            </Col>
          </Row>


          <Row>
            <Period />
          </Row>

        </Container>

      </TimelineStyles>

    </section>
  );
}

export default Timeline;