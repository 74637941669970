import "./NotFound.css";
import { Link } from "react-router-dom";
import Footer from "../footer";
import Navigation from "../navbar";



export default function NotFound({ error }) {
  return (
    <>
      <Navigation />

      <section className="page_404">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              <div
                className="four_zero_four_bg"
                style={{
                  marginTop: "4rem"
                }}
              >
                <h1 className="text-center">404</h1>


              </div>

              <div className="content_box_404">
                <h3 className="h2">
                  Looks like you're lost
                </h3>

                <p
                  className="errorMsg"
                >{error ? error : (<p>
                  The page you are looking for is not available! Please check the URL
                </p>)} </p>

                <Link
                  to="/"
                  className="link_404"
                >Go to Home
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>


      <Footer />
    </>
  )
}