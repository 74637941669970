import React from 'react';
import styled from 'styled-components';
import './SubTitle.css';

const SubTitleComponent = styled.h2`
		display: block;
		margin-top: 12px;
`;

function SubTitle({ textColor, text, textAlign, style, cName, children }) {


  return (
    <SubTitleComponent
      data-aos="fade-up"
      data-aos-once="true"
      className={`subtitle ${cName}`}
      style={{
        color: textColor,
        textAlign: textAlign,
        ...style
      }}
    >
      {children ? children : null}
      {text ? text : null}

    </SubTitleComponent>
  );
}

export default SubTitle;