import eraconnecktLogo from '../../../assets/img/eraconneckt-logo.svg';
import facebook from '../../../assets/social/facebook.svg';
import instagram from '../../../assets/social/instagram.svg';
import twitter from '../../../assets/social/twitter.svg';
import styled from 'styled-components';


const SocialMediaContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: start;
	margin: 1rem auto;
`;

const Ul = styled.ul`
	list-style: none;
	margin-left: -40px;
`;

const Li = styled.li`
	display: inline;
	margin: auto 12px;
`;

export default function FirstCol() {
  return (
    <>
      <div
        data-aos="fade-up"
        data-aos-once="true"
      >
        <a href="/"   hrefLang="en-us">
          <img
            src={eraconnecktLogo}
            alt="Eraconneckt logo"
          />
        </a>
      </div>

      <SocialMediaContainer>
        <Ul>
          <Li>
            <a href="https://twitter.com/EConneckt" hrefLang="en-us" >
              <img
                src={twitter}
                alt="Twitter icon"
              />
            </a>
          </Li>

          <Li>
            <a href="https://facebook.com/eraconneckt" hrefLang="en-us">
              <img
                src={facebook}
                alt="Facebook icon"
              />
            </a>
          </Li>

          <Li>
            <a href="https://www.instagram.com/eraconneckt/" hrefLang="en-us">
              <img
                src={instagram}
                alt="Instagram icon"
              />
            </a>
          </Li>

        </Ul>
      </SocialMediaContainer>

    </>
  )
}