
export const PORTAL_MANAGER = "/portal-manager"
export const ADMIN = `/admin`;
export const AUTH = `/auth`;
export const HOME = `/`;
export const WILD_CARD = `*`;
export const SURVEY = `/survey`;
export const CREATE = `/create`;
export const EDIT = `/edit`;
export const DONATION = `/donation`;
export const GET_STARTED = `/get-started`;
export const BLOG = `/blog`;
export const JOB = `/job`;
export const WRITE = `/write`;
export const SINGLE_BLOG = `${BLOG}/:id`;
export const DRAFT = `/draft`;
export const SINGLE_DRAFT = `${DRAFT}/:id`;
export const ARCHIVED = `/archived`;
export const RESET_PASSWORD = `${AUTH}/reset-password`;
export const FORGOT_PASSWORD = `${AUTH}/forgot-password`;
export const RESET_LINK_SENT = `${AUTH}/reset-link-sent`;
export const PASSWORD_RESET_SUCCESSFUL = `${AUTH}/password-reset-successful`;
export const CREATE_NEW_PASSWORD = `${AUTH}/create-new-password`;
export const SIGN_UP = `${AUTH}/sign-up`;
export const SIGN_IN = `${AUTH}/sign-in`;
export const LOG_IN = `${AUTH}/login`;
export const ADMIN_JOB = `${ADMIN}${JOB}`;
export const ADMIN_BLOG = `${ADMIN}${BLOG}`;
export const ADMIN_DRAFT = `${ADMIN}${DRAFT}`;
export const ADMIN_ARCHIVED = `${ADMIN}${ARCHIVED}`;
export const ADMIN_WRITE = `${ADMIN}${WRITE}`;
export const ADMIN_CREATE_JOB = `${ADMIN}${CREATE}${JOB}`;
export const ADMIN_EDIT_JOB = `${ADMIN}${EDIT}${JOB}/:id`;
export const ADMIN_EDIT_DRAFT = `${ADMIN}${EDIT}${DRAFT}/:id`;
export const ADMIN_EDIT_BLOG = `${ADMIN}${EDIT}${BLOG}/:id`;
