import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { HorizontalRule } from "../../utils/horizontalrule/HorizontalRule";
import Card from "./Card";
import partnerImg from "../../assets/donate/partnerImg.png";
import donateImg from "../../assets/donate/donateImg.png";
import outreachImg from "../../assets/donate/outreachImg.png";
import DonationOverlay from "./components/donationoverlay";
import { constants } from "../../constants/constants";
import { useLocation } from "react-router-dom";
import { scroller } from "react-scroll";
/*
 * This component was formerly called `Donate`
 */

export default function Donate() {
  const [showDonationOverlay, setShowDonationOverlay] = useState(false);
  const handleDonationOverlay = () => {
    setShowDonationOverlay(!showDonationOverlay);
  };
  const location = useLocation();
  const [whoIsDonating, setWhoIsDonating] = useState("");

  const { VOLUNTEER, PARTNERSHIP } = constants;

  useEffect(() => {
    if (location.pathname === "/donation") {
      const timer = setTimeout(() => {
        scroller.scrollTo("work", {
          duration: 800,
          delay: 0,
          smooth: "easeInOutQuart",
          offset: -50,
        });
        setShowDonationOverlay(true);
        setWhoIsDonating("Individual");
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [location.pathname]);

  return (
    <>
      <section id="work" className="section work-section">
        <Container>
          <HorizontalRule text="Work With Us" textColor="#727272" />

          <Row className="mt-5">
            <Col xs={{ span: 12 }} md={{ span: 4 }}>
              <Card
                cardBg={partnerImg}
                cardText="Partner"
                hoverText="Join us in developing initiatives that support African youth in achieving their full potentials while keeping to our shared priorities and values"
                link1={`/survey?type=${PARTNERSHIP}`}
                linkText1="Partner with us"
                link2={null}
                linkText2={false}
                isDonate={false}
              />
            </Col>

            <Col xs={{ span: 12 }} md={{ span: 4 }}>
              <Card
                cardBg={donateImg}
                cardText="Donate"
                hoverText="By making a donation, you can support our programs and efforts to empower young people in Africa."
                link1="info@eraconneckt.com"
                linkText1="As an individual"
                link2="info@eraconneckt.com"
                linkText2="As an organization"
                handleDonationOverlay={handleDonationOverlay}
                isDonate={true}
                setWhoIsDonating={setWhoIsDonating}
              />
            </Col>

            <Col xs={{ span: 12 }} md={{ span: 4 }}>
              <Card
                cardBg={outreachImg}
                cardText="Volunteer"
                hoverText="Do you possess the same passion and vision that Eraconneckt possesses? Join us as a volunteer and help transform Africa and your neighborhood."
                link1={`/survey?type=${VOLUNTEER}`}
                linkText1="Volunteer"
                link2={null}
                linkText2={false}
                isDonate={false}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              {showDonationOverlay && (
                <DonationOverlay
                  handleDonationOverlay={handleDonationOverlay}
                  whoIsDonating={whoIsDonating}
                />
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
