import styled from 'styled-components';
import Title from '../../../../utils/title/Title';
import SubTitle from '../../../../utils/subtitle/SubTitle';
import CloseButton from '../closebutton';
import { useModal } from '../../../../hooks/utils.hooks';
import Modal from '../../../../utils/modals/Modal';


export default function DonationOverlay({
  handleDonationOverlay,
  whoIsDonating,
  setWhoIsDonating
}) {

  const {
    open,
    openModal,
    closeModal
  } = useModal();

  return (

    <>

      <OverlayContainer>
        <OverlayContent>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <Title
              textColor=""
              text={`Donate as an ${whoIsDonating}`}
              textAlign="center"
              style={{
                color: "#89b369",
                fontSize: "normal",
                fontWeight: "600",
                marginRight: "1rem",
              }}
            />

            <CloseButton
              handleClick={handleDonationOverlay}
            />
          </div>


          <SubTitle
            textColor=""
            text="You can make your donation using any of the payment methods listed."
            textAlign="center"
            style={{
              color: "#727272",
              marginBottom: "2rem",
              marginTop: "0",
            }}
          />

          <DonationType>
            Card Donation
          </DonationType>


          <DonationType
            onClick={() => {
              openModal()
            }}
          >
            Wire Transfer
          </DonationType>

        </OverlayContent>

      </OverlayContainer>


      <Modal
        open={open}
        children={<ModalContent />}
        openModal={openModal}
        closeModal={closeModal}
      />

    </>
  )
};



const ModalContent = () => {



  return (
    <>
      <table>
        <tr>
          <td><b>Bank Name:</b></td>
          <td>GUARANTY TRUST BANK, NIGERIA</td>
        </tr>
        <tr>
          <td><b>Bank Account Name:</b></td>
          <td>ARE BASHIRAT</td>
        </tr>
        <tr> <td><b>Account Number:</b></td>
          <td>0751329897</td>
        </tr>
        <tr>
          <td><b>International Routing (SWIFT-BIC) Code:</b></td>
          <td>GTBIGB2L</td>
        </tr>
      </table>
    </>
  )
};



const OverlayContainer = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 2;
`;

const OverlayContent = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: #fff;
    border-radius: 4px;
    padding: 1rem;
    height: 400px;
    width: 90%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
`;

const DonationType = styled.div`
    width: 90%;
    padding: 12px;
    text-align: center;
    color: #727272;
    border: 1px solid #727272;
    border-radius: 4px;
    margin: 1rem auto;
    cursor: pointer;
    transition: all 0.4s ease-in;

      :hover {
        border: 1px solid #89b369;
        box-shadow: 0 4px 8px #89b3696e;
      }
`;

