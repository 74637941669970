import React from 'react'
import JobPageComponents from '../../../components/job/components/JobPageComponents'
import JobComponent from '../../../components/job/components/JobComponent'




export default function JobPage() {
  return (
    <>
      <JobPageComponents />
    </>
  )
}


export const EditJob = () => {
  return (
    <>
      <JobComponent />
    </>
  )
}

export const CreateJob = () => {
  return (
    <>
      <JobComponent />
    </>
  )
}