import React from 'react';
import SignIn from '../../../components/auth/sign-in';

function SignInPage() {
  return (

    <>
      <SignIn />
    </>
  );
}

export default SignInPage;