import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { HorizontalRule } from '../../utils/horizontalrule/HorizontalRule';
import Card from './Card';
import digitalWorkforce from '../../assets/focus/digital-work.png';
import economicDevelopment from '../../assets/focus/economic-dev.png';
import CommunityDevelopment from '../../assets/focus/community-dev.png';


function Focus() {
	return (
		<section
			id="focus"
			className="section focus-section"
		>
			<Container>

				<HorizontalRule
					text="Focus Areas"
					textColor="#727272"
				/>

				<Row
					className="mt-5"
				>

					<Col
						xs={{ span: 12 }}
						md={{ span: 4 }}
					>
						<Card
							cardText="DIGITAL WORKFORCE"
							cardBg={digitalWorkforce}
						/>
					</Col>

					<Col
						xs={{ span: 12 }}
						md={{ span: 4 }}
					>
						<Card
							cardText="ECONOMIC DEVELOPMENT"
							cardBg={economicDevelopment}
						/>
					</Col>

					<Col
						xs={{ span: 12 }}
						md={{ span: 4 }}
					>
						<Card
							cardText="COMMUNITY DEVELOPMENT"
							cardBg={CommunityDevelopment}
						/>
					</Col>

				</Row>

			</Container>

		</section>
	);
}

export default Focus;