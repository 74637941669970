import React from 'react';
import ResetPassword from '../../../components/auth/reset-password';

function ResetPasswordPage() {
  return (

    <>
      <ResetPassword />
    </>
  );
}

export default ResetPasswordPage;