import playIcon from '../../../assets/icons/play.svg';


const PlayIcon = () => {

	const styles = {
		height: "24px",
		width: "24px",
	};

	return (
		<>
			<img
				alt="play icon"
				src={playIcon}
				style={styles}
			/>
		</>
	);
};

export default PlayIcon;