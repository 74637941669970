import SubTitle from "../../../utils/subtitle/SubTitle";

export default function SecondCol() {
  return (
    <div data-aos="fade-up" data-aos-once="true">
      <SubTitle
        textColor=""
        text="Quick Links"
        textAlign="left"
        style={{ fontWeight: "bold" }}
      />

      <div>
        <a
          hrefLang="en-us"
          className="footer-link"
          target="_blank"
          href="https://eraconneckt.com/"
          rel="noreferrer"
        >
          eraconneckt.com
        </a>
      </div>

      <div>
        <a
          hrefLang="en-us"
          className="footer-link"
          target="_blank"
          href="https://eraconneckt.org"
          rel="noreferrer"
        >
          eraconneckt.org
        </a>
      </div>
    </div>
  );
}
