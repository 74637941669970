import { useState } from "react";
import styled from "styled-components";
import Files from 'react-files'
import { useExport } from "./../../../../../hooks/useExport";
import { truncate } from "../../../../../utils/truncate/truncate";




const Img = styled.img`
  max-width: 18px;
  max-height: 18px;
  margin-right: 0.825rem;
`;


export default function ChooseFeaturedImage({
  setFeaturedImage,
  featuredImage
}) {

  const { featuredImageIcon } = useExport();
  const [fileName, setFileName] = useState("");


  const onFilesChange = (files) => {



    const file = files[0];
    // const fileURL = file.preview.url;
    const fileName = file.name;
    setFileName(fileName);
    setFeaturedImage(file);

  };

  const onFilesError = (error, file) => {
    console.log('error code ' + error.code + ': ' + error.message)
  };

  return (
    <>

      <div className="files">

        <Files
          className={`blog-tools-form__input files-dropzone ${!fileName ? '' : ''}`}
          onChange={onFilesChange}
          onError={onFilesError}
          accepts={['image/png', 'image/jpg', 'image/jpeg']}
          multiple={false}
          maxFileSize={10000000}
          minFileSize={0}
          clickable
          dragActiveClassName="files-dropzone-active"
          style={{
            cursor: "pointer",
          }}
          name="featuredImage"
          required
        >
          <Img
            src={featuredImageIcon}
            alt="image-icon"
          />

          {fileName ?
            truncate(fileName, 15)
            :
            <span>
              Choose file
            </span>
          }


        </Files>

      </div>

    </>
  )
}