import { Container, Row, Col } from 'react-bootstrap';
import { Colors } from '../../../../utils/colors/colors';
import Title from '../../../../utils/title/Title';
import SubTitle from '../../../../utils/subtitle/SubTitle';



export default function BlogHeader({ slug }) {

  const {
    lightestOrange,
    whiteColor
  } = Colors();


  const word = slug;
  const firstLetter = word.charAt(0)
  const firstLetterCap = firstLetter.toUpperCase()
  const remainingLetters = word.slice(1)
  const capitalizedWord = firstLetterCap + remainingLetters

  return (
    <>
      <section
        className="section blog-header-section"
        id="blog-header"
        style={{
          backgroundColor: lightestOrange,
        }}
      >

        <Container>
          <Row>
            <Col
              xs={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              style={{
                margin: "1rem auto",
                maxWidth: "700px",
                minHeight: "60vh"
              }}
            >
              <Title
                textColor={whiteColor}
                text={`${capitalizedWord}`}
                textAlign="center"
                style={{}}
              />

              <SubTitle
                textColor={whiteColor}
                text="Keep up with the most recent industry news, interviews, insights, and resources."
                textAlign="center"
                style={{}}
              />


            </Col>

          </Row>


        </Container>

      </section>

    </>
  )
}