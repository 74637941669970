import React from 'react';
import styled from 'styled-components';
import './Title.css';

const TitleComponent = styled.h1`
		display: block;
		font-family: "Lato" !important;
		font-size: 1.525rem;
		margin-top: 12px;
		line-height: 2rem;

		@media (min-width: 768px) {
			font-size: 1.8rem;
		}

		@media (min-width: 1024px) {
			line-height: 4rem;
			font-size: 2.35rem;
		}

		@media (min-width: 1440px) {
		    font-size: calc(1.90*16px);
		}
`;


function Title({ textColor, text, textAlign, style }) {

  return (
    <TitleComponent
      data-aos="fade-up"
      data-aos-once="true"
      className="title"
      style={{
        color: textColor,
        textAlign: textAlign,
        ...style
      }}
    >
      {text}
    </TitleComponent>
  );
}

export default Title;