import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Navigation from "../navbar";
import AdminsTable from "./components/AdminsTable";



export default function AdminPanel() {

  const [title, setTitle] = useState("Eraconneckt | Admin Panel");



  useEffect(() => {
    setTitle(`Eraconneckt | Admin Panel`);
  }, []);


  useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    document.title = title;
  }, [title]);


  return (
    <>
      <Navigation />
      <Container>

        <p
          className="heading"
        >
          Admin Panel
        </p>

      </Container>
      <AdminsTable />
    </>
  )
}