import PostIndex from "../postindex/PostIndex";


export default function Blog() {


  return (
    <>
      <PostIndex
        slug="draft"
      />
    </>
  )
}