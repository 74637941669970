export default function Text({ timeImg, timelineHeading }) {

	return (
		<>
			<div
				className="image">
				<img
					src={timeImg}
					alt={timelineHeading}
					className="timeline-img"
				/>
			</div>
		</>
	)
}