import styled from 'styled-components';


const Text = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font-size: inherit;
  font-color: inherit;
`

export default function ReadMore() {
  return (
    <Text>
      Read more ...
    </Text>
  )
}