
import PostComponents from "../components/PostComponents";



export default function EditBlog() {

  return (
    <>
      <PostComponents />
    </>
  )
}