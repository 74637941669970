import { Fragment } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import Card from './components/Card';
import { useFetch } from '../../../../hooks/useFetch';
import Loader from "../../../../utils/loader/Loader";
import { constants } from "../../../../constants/constants";
import { useState } from "react";
import { useEffect } from "react";
import PageNavigation from "../../../../utils/page-navigation/PageNavigation";








export default function BlogsCard({ slug }) {




  const API_URL = constants.API_URL;
  const NUMBER_OF_BLOGS_CARD_PER_PAGE = constants.NUMBER_OF_BLOGS_CARD_PER_PAGE;
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const {
    isPending, error, data
  } = useFetch(`${API_URL}/${slug}s`, {});

  if (error) {
    console.error(error);
  }

  const items = data?.data;

  // Invoked when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset =
      (event?.selected * NUMBER_OF_BLOGS_CARD_PER_PAGE) % items?.length;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    const endOffset = itemOffset + NUMBER_OF_BLOGS_CARD_PER_PAGE;
    setCurrentItems(items?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items?.length / NUMBER_OF_BLOGS_CARD_PER_PAGE));
  }, [NUMBER_OF_BLOGS_CARD_PER_PAGE, itemOffset, items]);


  return (
    <>
      <section
        className="section blog-cards-section"
        id="blog-cards"
        style={{
          position: "relative",
          top: "-25vh"
        }}
      >

        <Container>
          <Row className="mt-5">
            <Loader error={error} isPending={isPending} />

            {!isPending && currentItems && (
              <Fragment>
                {currentItems?.map((blog, index) => (

                  <Col
                    xs={{ span: 12 }}
                    md={{ span: 6 }}
                    lg={{ span: 4 }}
                    key={blog?.id}
                  >
                    <Card
                      blogTitle={blog?.blogTitle}
                      blogBody={blog?.blogBody}
                      date={blog?.date}
                      timeToRead={blog?.timeToRead}
                      blogpostImage={blog?.featuredImage}
                      id={blog?.id}
                      slug={slug}
                    />

                  </Col>
                ))}
                <PageNavigation
                  isPending={isPending}
                  error={error}
                  data={currentItems ?? currentItems}
                  pageCount={pageCount}
                  handlePageClick={handlePageClick}
                />
              </Fragment>
            )}
          </Row>

        </Container>
      </section>
    </>
  )
}