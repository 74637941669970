import { Fragment } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import SubTitle from "./../../../../utils/subtitle/SubTitle";
import { Colors } from "./../../../../utils/colors/colors";
import FormattingArea from './components/FormattingArea';
import TitleInputArea from './components/TitleInputArea';
import BodyInputArea from "./components/BodyInputArea";






export default function BlogTextArea({
  blogTitle,
  blogBody,
  setBlogTitle,
  setBlogBody,
  isJobScreen
}) {


  const {
    primaryColor
  } = Colors();





  return (
    <>
      <section
        className="section write-blog-section"
        id="write-blog"
      >
        <Container>
          <Row>
            <Col
              xs={{ span: 12, order: 1 }}
              md={{ span: 6, order: 1 }}
              lg={{ span: 6, order: 1 }}
            >

              <SubTitle
                textColor={primaryColor}
                text="Content"
                textAlign="left"
                style={{
                  fontWeight: "bold",
                  margin: "0"
                }}
              />

            </Col>

            <Col
              xs={{ span: 12, order: 2 }}
              md={{ span: 6, order: 2 }}
              lg={{ span: 6, order: 2 }}
            >
              <FormattingArea />

            </Col>

          </Row>

          <Row>
            <Col>
              <TitleInputArea
                blogTitle={blogTitle}
                setBlogTitle={setBlogTitle}
                isJobScreen={isJobScreen}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <BodyInputArea
                blogBody={blogBody}
                setBlogBody={setBlogBody}
                isJobScreen={isJobScreen}
              />
            </Col>
          </Row>

        </Container>

      </section>
    </>
  )
}