import logo from '../../src/assets/logo/logo.svg';
import coloredLogo from '../../src/assets/logo/logo-colored.svg';
import asideBg from '../../src/assets/hero/hero.png';
import linkedin from '../assets/icons/linkedin.svg';
import twitter from '../assets/icons/twitter.svg';
import instagram from '../assets/icons/instagram.svg';
import facebook from '../assets/icons/facebook.svg';
import facebookShare from '../assets/icons/blog/facebook-black.svg';
import twitterShare from '../assets/icons/blog/twitter-black.svg';
import instagramShare from '../assets/icons/blog/instagram-black.svg';
import linkedinShare from '../assets/icons/blog/linkedin-black.svg';
import editIcon from '../assets/icons/edit-icon.svg';
import trashIcon from '../assets/icons/trash-icon.svg';
import featuredImageIcon from '../assets/icons/feature-image-icon.svg';
import userNavbarToggler from '../assets/icons/user-nav-toggler-icon.svg';
import searchIcon from '../assets/icons/search-icon.svg';
import plusIcon from '../assets/icons/plus-icon.svg';
import deleteIcon from '../assets/icons/delete-icon.svg';










export const useExport = () => {
  return {
    logo,
    coloredLogo,
    asideBg,
    facebook,
    twitter,
    linkedin,
    instagram,
    facebookShare,
    twitterShare,
    instagramShare,
    linkedinShare,
    editIcon,
    trashIcon,
    featuredImageIcon,
    userNavbarToggler,
    searchIcon,
    plusIcon,
    deleteIcon
  }
}