    import { Fragment, useState, useEffect } from "react";
import Footer from "../../../footer";
import Navigation from "../../../navbar";
import ArticleContainer from "./components";
import FeaturedImage from "./components/featured-image/FeaturedImage";
import { useParams } from "react-router-dom";
import { useFetch } from "../../../../hooks/useFetch";
import { API_URL } from "../../../../services/auth.service";
import Loader from "../../../../utils/loader/Loader";
import styled from "styled-components";
import "./SingleBlog.css";
import { HelmetProvider } from 'react-helmet-async';
import SEO from "../../../../utils/seo/SEO";






const PageContainer = styled.div`
  min-height: 80vh;
`;

export default function SingleBlog() {
  const [title, setTitle] = useState("Eraconneckt | Blog");

  let { id } = useParams();

  const pathArray = window.location.pathname.split("/");
  const slug = pathArray[1];

  const {
    isPending, error, data
  } = useFetch(`${API_URL}/${slug}s/${id}`, {});

  if (error) {
    console.error(error);
  }

  const article = data.data;

  useEffect(() => {

    if (!isPending) {

      setTitle(`${article?.blogTitle ? article?.blogTitle : "Blog"} | Eraconneckt`);

    }
  }, [article, article?.blogTitle, isPending]);

  useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    document.title = title;
  }, [title]);



  return (
    <>
      <HelmetProvider>
        <SEO
          title={`${article?.blogTitle} | Eraconneckt`}
          description={`${article?.blogTitle} | Eraconneckt`}
          name={`Eraconneckt`}
          type={`Article`}
          image={article?.featuredImage}
          link={window.location.href}
        />
        <Navigation />

        <PageContainer>
          {isPending ?

            (
              <Fragment>
                <Loader />
              </Fragment>
            ) : (
              <Fragment
                key={article?.id}
              >

                <FeaturedImage
                  featuredImage={article?.featuredImage}
                />

                <ArticleContainer
                  id={article?.id}
                  blogTitle={article?.blogTitle}
                  blogBody={article?.blogBody}
                  date={article?.date}
                  author={article?.author}
                />

              </Fragment>
            )}

        </PageContainer>


        <Footer />
      </HelmetProvider>
    </>
  )
}