import React from 'react';
import { Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import missionBg from '../../assets/mission/missionBg.png';
import { HorizontalRule } from '../../utils/horizontalrule/HorizontalRule';
import SubTitle from '../../utils/subtitle/SubTitle';



const MissionStyles = styled.div`
	min-height: 250px;
	background-color: #222b52;
	background: linear-gradient(0deg, rgba(32, 31, 79, 0.9), rgba(32, 31, 79, 0.9));
	background-image: url(${missionBg});
	background-repeat: no-repeat;
	background-size: cover;
`;

function Mission() {
	return (
		<>
			<MissionStyles>
				<section
					className="section mission"
					id="mission">

					<Container>

						<HorizontalRule
							text="Mission"
							textColor="#fff"
							style={{ marginTop: "1rem" }}
						/>

						<Row>
              <SubTitle
								textColor="#fff"
								textAlign="center"
                text="We are on a Mission to Conneckt  Africans to Opportunities"
								style={{ fontWeight: "bold", margin: "1.625rem auto" }}
							/>

						</Row>
					</Container>

				</section>
			</MissionStyles>
		</>
	);
}

export default Mission;