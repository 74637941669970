import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './App.css';
import Ui from './components/Ui';
import { BrowserRouter } from "react-router-dom";


// TODO: Update TRACKING_ID to actual ID
// Start Google Analytics Code
import ReactGA from 'react-ga';
const TRACKING_ID = "UA-XXXXX-X"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
// End Google Analytics Code










const App = () => {
	useEffect(() => {
		AOS.init({
			delay: 100,
		});
		AOS.refresh();
	}, []);

	return (
		<>
			<BrowserRouter>
				<Ui />
			</BrowserRouter>
		</>
	)
}
export default App;

