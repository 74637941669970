import { useState, useEffect, Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Navbar from '../../../components/navbar';
import Card from '../../../components/blog/components/blogs-cards/components/Card';
import { useFetch } from '../../../hooks/useFetch';
import Footer from '../../../components/footer';
import { Link, useLocation } from "react-router-dom";
import { useLocationPathName } from "./../../../hooks/useLocationPathname";
import Loader from "../../../utils/loader/Loader";
import { constants } from '../../../constants/constants';
import PageNavigation from '../../../utils/page-navigation/PageNavigation';





export default function AdminDraftComponent({ slug }) {

  const location = useLocation();

  const { result } = useLocationPathName(location);

  const [title, setTitle] = useState("Eraconneckt | Draft");


  const URL = constants.API_URL;
  const NUMBER_OF_BLOGS_CARD_PER_PAGE = constants.NUMBER_OF_BLOGS_CARD_PER_PAGE;
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const {
    isPending,
    error,
    data
  } = useFetch(`${URL}/drafts`, {});

  if (error) {
    console.error(error);
  }

  useEffect(() => {

    if (!isPending) {

      setTitle(`Eraconneckt | Drafts`);

    }
  }, [isPending]);


  useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    document.title = title;
  }, [title]);

  const items = data?.data;

  // Invoked when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset =
      (event?.selected * NUMBER_OF_BLOGS_CARD_PER_PAGE) % items?.length;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    const endOffset = itemOffset + NUMBER_OF_BLOGS_CARD_PER_PAGE;
    setCurrentItems(items?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items?.length / NUMBER_OF_BLOGS_CARD_PER_PAGE));
  }, [NUMBER_OF_BLOGS_CARD_PER_PAGE, itemOffset, items]);


  return (
    <>
      <Navbar />

      <Container>

        <p
          className="heading"
        >
          {result}
        </p>

        <Row className="mt-5">
          <Loader error={error} isPending={isPending} />

          {!isPending && currentItems && (
            <Fragment>
              {currentItems?.map((blog, index) => (

                <Col
                  xs={{ span: 12 }}
                  md={{ span: 6 }}
                  lg={{ span: 4 }}
                  key={blog?.id}
                >
                  <Link
                    to={`/draft/${blog?.id}`}
                  >
                    <Card
                      blogTitle={blog?.blogTitle}
                      blogBody={blog?.blogBody}
                      date={blog?.date}
                      timeToRead={blog?.timeToRead}
                      blogpostImage={blog?.featuredImage}
                      id={blog?.id}
                      slug="draft"
                    />
                  </Link>

                </Col>

              ))}
              <PageNavigation
                isPending={isPending}
                error={error}
                data={currentItems ?? currentItems}
                pageCount={pageCount}
                handlePageClick={handlePageClick}
              />
            </Fragment>
          )}
        </Row>

      </Container>

      <Footer />
    </>
  )
}