export const Colors = () => {

  const
    primaryColor = "#89B369", // green
    secondaryColor = "rgb(235, 129, 69)", // orange
    tertiaryColor = "#272727", // gray color
    whiteColor = "#fff", // white color
    dangerColor = "#FF5151", // reddish
    lightGrayColor = "#848484", // brand light gray color
    fourthColor = "#4f4f4f"; // dark gray

  return (
    {
      primaryColor,
      secondaryColor,
      tertiaryColor,
      whiteColor,
      fourthColor,
      dangerColor,
      lightGrayColor
    }
  );
};