



export const constants = {
  API_URL: `${process.env.REACT_APP_API_URL}/v1`,
  NUMBER_OF_BLOGS_CARD_PER_PAGE: `${process.env.REACT_APP_NUMBER_OF_BLOGS_CARD_PER_PAGE}`,
  TINYMCE_KEY: `${process.env.REACT_APP_TINYMCE_API_SECRET}`,
  ATTENDEE: "attendee",
  PARTNERSHIP: "partnership",
  VOLUNTEER: "volunteer",
  USER_INTERVIEW_QUESTIONNAIRE: "user-interview",
  ALUMNI_SUCCESS_TRACKER: "alumni",
  APPLICANT_QUESTIONNAIRE: "applicant-question"
}

export const roles = {
  ADMIN: "Admin",
  CMS_ADMIN: "CMS Admin",
  HR_ADMIN: "HR Admin"
};

export const userStatus = {
  ACTIVE: "active",
  INACTIVE: "inactive",
};

export const rolesSelectOptions = [
  { value: roles.ADMIN, label: roles.ADMIN },
  { value: roles.CMS_ADMIN, label: roles.CMS_ADMIN },
  { value: roles.HR_ADMIN, label: roles.HR_ADMIN },
];


export const pageErrors = {
  NOT_FOUND: "Sorry, but that page cannot be found!",
  ACCOUNT_IS_DISABLED: "Sorry, your account is disabled! Please contact administrator.",
  INVALID_TOKEN: "Invalid or no auth token provided",
  INSUFFICIENT_PERMISSION: "Sorry, you do not have sufficient permission to view that page."
}