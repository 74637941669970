import styled from 'styled-components';
import { Colors } from "./../../../../../utils/colors/colors";
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import authService from '../../../../../services/auth.service';
import { Icons } from '../../../../../hooks/useIcons';
import ButtonComponent from '../../../../../utils/button/ButtonComponent';
import CloseButton from "./../../../../donate/components/closebutton/index";
import Select from 'react-select';
import { rolesSelectOptions } from '../../../../../constants/constants';







const {
  primaryColor,
  whiteColor,
  lightGrayColor
} = Colors();



const Wrapper = styled.div`

  display: block;
  align-items: center;
  gap: 30px;
  width: 600px;
  background: ${whiteColor};
  border-radius: 6px;
  margin: auto 1rem;
`;

const Header = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  width: 100%;
  padding: 1.235rem;
  color: #212429;
  border-bottom: 0.5px solid ${lightGrayColor};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;








const FormContainer = styled.div`
  width: 90%;
  margin: auto auto;
  margin-top: 1rem;

  @media (min-width: 1024px) {
    ${'' /* width: 70%; */}
  }
`;

const FormContent = styled.div`

`;






export default function CreateAdminForm({
  setShowOverlay
}) {



  const [authError, setAuthError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [otherError, setOtherError] = useState("");
  const [seePassword, setSeePassword] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const { register, formState: { errors }, handleSubmit } = useForm();



  const handleRole = (selectedRole) => {
    setSelectedRole(selectedRole);
  };

  const onSubmit = async (data) => {
    // console.log(data);
    setIsLoading(true);


    try {
      await authService.signup(data.email, data.password, selectedRole.value).then(
        () => {
          setShowOverlay(false);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        },
        (error) => {
          console.error(error);
          setOtherError(error?.message);
          setAuthError(error?.response.data.error);
          setIsLoading(false);
        }
      );

    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    otherError && setIsLoading(false);
  }, [otherError]);

  const {
    FaRegEye,
    FaRegEyeSlash,
  } = Icons();

  return (

    <>
      <Wrapper
      >
        <Header>
          <div>
            Add new admin account
          </div>

          <CloseButton
            handleClick={(e) => setShowOverlay(false)}
          />

        </Header>

        <FormContent>
          <FormContainer
            style={{}}
          >
            <FormContent
              style={{
                backgroundColor: whiteColor,
                width: "100%",
                padding: "1rem",
                paddingTop: "0"
              }}
            >


              <Form>
                <Form.Group>

                  <Form.Label
                    style={{
                      margin: "12px auto",
                      color: lightGrayColor,
                    }}
                  >
                    Email address
                  </Form.Label>

                  <Form.Control
                    className={`${errors.email ? 'input-error' : ''}`}
                    name="email"
                    {...register("email", { required: "Please enter email" })}
                    type="text"
                    autoComplete="off"
                    placeholder="Enter email"
                    style={{
                      backgroundColor: whiteColor,
                      padding: "12px 28px",
                    }}
                  />

                  {errors.email && (
                    <p className="errorMsg">{errors.email.message}</p>
                  )}



                  <Form.Label
                    style={{
                      margin: "12px auto",
                      color: lightGrayColor,
                    }}
                  >
                    Role
                  </Form.Label>


                  <Select
                    options={rolesSelectOptions}
                    name="role"
                    type="text"
                    autoComplete="off"
                    placeholder="Select role"
                    style={{
                      backgroundColor: whiteColor,
                      padding: "12px 28px",
                    }}
                    onChange={handleRole}
                  />

                  <Form.Label
                    style={{
                      margin: "12px auto",
                      color: lightGrayColor,
                    }}
                  >
                    Password
                  </Form.Label>

                  <Form.Control
                    className={`${errors.password ? 'input-error' : ''}`}
                    name="password"
                    {...register("password", { required: "Please input password" })}
                    type={seePassword ? 'text' : 'password'}
                    autoComplete="off"
                    placeholder="Enter password"
                    style={{
                      backgroundColor: whiteColor,
                      padding: "12px 28px",
                    }}
                  />

                  {seePassword ?
                    <FaRegEyeSlash
                      className="auth-icon"
                      onClick={() => setSeePassword(false)}
                    />
                    :
                    <FaRegEye
                      className="auth-icon"
                      onClick={() => setSeePassword(true)}
                    />}

                  {errors.password && (
                    <p className="errorMsg">{errors.password.message}</p>
                  )}

                </Form.Group>


              </Form>


              {authError &&
                (<p
                  className="errorMsg"
                >
                  Error occurred: {" "}
                  {authError}
                </p>
                )
              }

              {otherError &&
                (<p
                  className="errorMsg"
                >
                  Error occurred: {" "}
                  {otherError}
                </p>
                )
              }



              <ButtonComponent
                bgColor={primaryColor}
                text={!isLoading ? "Create admin" : "Creating admin ..."}
                style={{
                  minWidth: "100%",
                  margin: "2.125rem auto 1rem auto",
                }}
                click={handleSubmit(onSubmit)}
                isLoading={isLoading}
              />

            </FormContent>
          </FormContainer>
        </FormContent>
      </Wrapper>
    </>
  )
}