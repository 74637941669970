import React from 'react';
import styled from 'styled-components';
import { useCreatedAt } from '../../../../../hooks/useCreatedAt';
import { Colors } from '../../../../../utils/colors/colors';
import SubTitle from '../../../../../utils/subtitle/SubTitle';
import ReadMore from "../../../../utils/read-more/ReadMore";
import DOMPurify from 'dompurify';


const {
  primaryColor,
  tertiaryColor,
  fourthColor
} = Colors();


const Latest = styled.span`
    padding: 2px 14px;
    background-color: ${primaryColor};
    border-radius: 12px;
    color: #fff;
`;

const DateAndTime = styled.div`
    font-size: small;
    font-weight: 500;
    color: #2b2b2b;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;


function Left({ latestBlogTitle, latestBlogBody, date }) {


  return (
    <div
      className=""
      style={{
        minHeight: "400px",
        maxHeight: "400px",
      }}
    >
      <Latest>
        Latest
      </Latest>

      <SubTitle
        cName="clamped-text"
        textColor={tertiaryColor}
        text={latestBlogTitle}
        textAlign="left"
        style={{
          fontSize: "2rem",
          lineHeight: "2.4rem",
          fontWeight: "600",
        }}
      />

      <SubTitle
        textColor={tertiaryColor}
        text=""
        textAlign="left"
        style={{
          margin: "0",
        }}
        cName="clamped-text"
      >
        <p
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(latestBlogBody) }}
          className='clamped-text'></p>
      </SubTitle>

      <Wrapper>

        <DateAndTime
          style={{
            color: fourthColor
          }}
        >
          {useCreatedAt(date)}
        </DateAndTime>

        <ReadMore />

      </Wrapper>

    </div>
  );
}

export default Left;