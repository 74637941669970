import Focus from "../../components/focus/Focus";
import Hero from "../../components/hero/Hero";
import Processes from "../../components/processes/Processes";
import VideoContent from "../../components/videocontent/VideoContent";
import Mission from "../../components/mission/Mission";
import Timeline from "../../components/timeline/Timeline";
import Donate from "../../components/donate/Donate";
import Contact from "../../components/contact/Contact";
// import Partners from '../../components/partner/Partners';
import Footer from "../../components/footer";
import Statistics from "../../components/statistics/Statistics";

export default function Home() {
  return (
    <>
      <Hero />
      <Statistics />
      <VideoContent />
      <Processes />
      <Mission />
      <Focus />
      <Timeline />
      <Donate />
      <Contact />
      {/* <Partners /> */}
      <Footer />
    </>
  );
}
