import { Container, Row, Col } from 'react-bootstrap';
import Left from './components/Left';
import Right from './components/Right';
import { Colors } from '../../../../utils/colors/colors';
import useAnalyticsEventTracker from '../../../../hooks/useAnalyticsEventTracker';





export default function LatestBlog({
  latestBlogBg,
  latestBlogId,
  latestBlogTitle,
  latestBlogBody,
  date,
  timeToRead
}) {

  const {
    whiteColor
  } = Colors();


  const gaEventTracker = useAnalyticsEventTracker('Latest blog');

  return (
    <section
      className="section blog-section-section"
      id="blog-section"
      style={{
        position: "relative",
        top: "-25vh"
      }}
    >
      <Container>
        <Row
          style={{
            padding: "0",
            margin: "1rem auto"
          }}
        >
          <Col
            xs={{ span: 12, order: 2 }}
            md={{ span: 6, order: 2 }}
            lg={{ span: 6, order: 2 }}
            style={{
              backgroundColor: whiteColor,
              padding: "2rem",
              borderTopRightRadius: "4px",
              borderBottomRightRadius: "4px",
              boxShadow: "2px 2px 4px 0 rgba(0, 0, 0, 0.1)",
              maxHeight: "400px"
            }}
            onClick={() => gaEventTracker('Contact')}
          >
            <Left
              id={latestBlogId}
              latestBlogId={latestBlogId}
              latestBlogTitle={latestBlogTitle}
              latestBlogBody={latestBlogBody}
              date={date}
              timeToRead={timeToRead}
            />
          </Col>

          <Col
            xs={{ span: 12, order: 1 }}
            md={{ span: 6, order: 1 }}
            lg={{ span: 6, order: 1 }}
            style={{
              padding: "0"
            }}
          >
            <Right
              latestBlogBg={latestBlogBg}
            />
          </Col>
        </Row>
      </Container>
    </section>
  )
}