import React from 'react';
import styled from 'styled-components';
import blogHeroBg from '../../assets/hero/blogHero.png';
import BlogHeader from '../blog/components/blog-header';
import NavComponent from '../navbar';

const Section = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	background-color: transparent;
	background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(${blogHeroBg});
`;

const BlogHero = ({ slug }) => {
	return (
		<Section>
			<section
				id="blog-hero"
				className="section hero-section">
				<NavComponent
				/>

        <BlogHeader
          slug={slug}
        />
			</section>

		</Section>
	)
}

export default BlogHero;