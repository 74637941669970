
import './Loader.css';


export default function Loader({
  styles,
  isPending,
  error
}) {
  return (
    <>
      {isPending && !error &&
        <div
          style={{
            ...styles
          }}
          className="contain-loader">
          <div
            className="loader-container"
          >
            <div className="load">
            </div>
          </div>
        </div>
      }
    </>
  )
}