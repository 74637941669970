import styled from 'styled-components';

const BlogBg = styled.div`
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 400px;
    max-height: 400px;
`;

function Right({ latestBlogBg }) {



  return (
    <BlogBg
      style={{
        backgroundImage: `url(${latestBlogBg})`,
        backgroundSize: "cover",
        borderTopLeftRadius: "4px",
        borderBottomLeftRadius: "4px",
      }}
    >

    </BlogBg>
  );
}

export default Right;