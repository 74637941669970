import React from 'react';
import Button from 'react-bootstrap/Button';




const ButtonComponent = ({
  bgColor,
  text,
  playIcon,
  href,
  style,
  click,
  isLoading
}) => {
  return (
    <>
      <Button
        className="btn-primary"
        href={href}
        variant="primary"
        bsPrefix="btn"
        onClick={click}
        style={{
          backgroundColor: bgColor,
          border: 0,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          padding: "16px 28px",
          color: "#fff",
          minWidth: "48px",
          maxWidth: "260px",
          borderRadius: "4px",
          cursor: "pointer",
          fontSize: "0.875rem",
          ...style
        }}
        disabled={isLoading}
      >
        {text} {playIcon && playIcon}
      </Button>
    </>
  );
}

export default ButtonComponent;