import React, { useEffect, useState } from 'react'
import Footer from '../../components/footer'
import Hero from '../../components/hero/Hero'
import Donate from '../../components/donate/Donate'
import Contact from '../../components/contact/Contact'




export default function DonatePage() {

  const [title, setTitle] = useState(`Donation | Eraconneckt`);

  useEffect(() => {
    document.title = title;
    setTitle(title);
  }, [title]);


  return (
    <>
      <Hero />
      <Donate />
      <Contact />
      <Footer />
    </>
  )
}
