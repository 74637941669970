import React from 'react';
import SignUp from "./../../../components/auth/sign-up/index";



function SignUpPage() {
  return (

    <>
      <SignUp />
    </>
  );
}

export default SignUpPage;