import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import styled from "styled-components";





export default function TitleInputArea({
  blogTitle,
  setBlogTitle,
  isJobScreen
}) {



  const { register, formState: { errors }, handleSubmit } = useForm();


  const pathArray = window.location.pathname.split('/');
  const slug = pathArray[3];




  const onSubmit = (data) => {
    setBlogTitle(data.title);
  };

  return (
    <>
      <TitleInputAreaContainer>
        <Title>
          {slug}
        </Title>

        <Form
          onSubmit={handleSubmit(onSubmit)}
          onChange={handleSubmit(onSubmit)}
        >
          <Form.Control
            className={`blog-tools-form__input ${errors.title ? '' : ''}`}
            name={isJobScreen ? "position" : "blogTitle"}
            placeholder={isJobScreen ? "Enter position" : "Enter blog title"}
            {...register(isJobScreen ? slug : "blog", {
              required: isJobScreen ?
                "Please enter job title" :
                "Please enter blog title"
            })}
            type="text"
            autoComplete="on"
            style={{
              backgroundColor: "",
              padding: "12px 28px",
              fontWeight: "500",
              minHeight: "100px",
              minWidth: "30vmax",
              fontFamily: "Lato",
            }}
            defaultValue={blogTitle}
            onChange={(e) => setBlogTitle(e.target.value)}
          />

          {errors && (
            <p className="errorMsg">
              {
                isJobScreen ? errors?.position?.message : errors?.title?.message
              }
            </p>
          )}

        </Form>
      </TitleInputAreaContainer>
    </>
  )
};










const TitleInputAreaContainer = styled.div`
  margin: 2rem auto;
  display: flex;
  justify-content: start;
`;

const Title = styled.div`
  max-width: 70px;
  border-right: 1px solid #696969;
  color: #696969;
  padding: 1rem;
  font-size: 1rem;
  margin-right: 1rem;
`;