import { Container, Row, Col } from 'react-bootstrap';
import Navbar from '../../../components/navbar';
import Card from '../../../components/blog/components/blogs-cards/components/Card';
import { useFetch } from '../../../hooks/useFetch';
import { blogData } from '../../../components/blog/data/data';
import { Link, useLocation } from "react-router-dom";
import { useLocationPathName } from "./../../../hooks/useLocationPathname";





export default function AdminArchiveComponent() {


  const location = useLocation();

  const { result } = useLocationPathName(location);

  const {
    isPending, error, data
  } = useFetch(blogData, blogData);

  if (error) {
    console.error(error);
  }


  return (
    <>
      <Navbar />

      <Container>

        <p
          className="heading"
        >
          {result}

        </p>

        <Row
          className="mt-5"
        >

          {data.map((blog, index) => (


            <Col
              xs={{ span: 12 }}
              md={{ span: 6 }}
              lg={{ span: 4 }}
              key={blog.id}
            >
              <Link
                to={`/blog/${blog.id}`}
              >
                <Card
                  blogTitle={blog.blogTitle}
                  date={blog.date}
                  timeToRead={blog.timeToRead}
                  blogpostImage={blog.blogpostImage}
                />
              </Link>

            </Col>

          ))}

        </Row>

      </Container>
    </>
  )
}