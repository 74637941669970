import styled from 'styled-components';
import ButtonComponent from "./../../../../../utils/button/ButtonComponent";
import { Colors } from "./../../../../../utils/colors/colors";
import { useParams } from "react-router-dom";
import { constants } from '../../../../../constants/constants';
import { toast } from "react-toastify";
import authHeader from '../../../../../services/auth-header';












const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;

    @media (min-width: 1024px) {
      justify-content: space-around;
      align-items: center;
    }
`;

export default function Buttons({
  handlePreview,
  handlePublish,
  isWriting,
  handleUpdate,
  slug,
  isLoading
}) {

  const API_URL = constants.API_URL;
  const { id } = useParams();
  const blog_API_URL = `${API_URL}/${slug}s/${id}`;




  const {
    primaryColor,
    whiteColor,
    tertiaryColor
  } = Colors();



  const deleteDraft = () => {
    const headers = authHeader();

    fetch(blog_API_URL, {
      method: "DELETE",
      headers: { "Content-Type": "application/json", ...headers },
      body: "",
    }).then((data) => {
      console.log(data.statusText);
      toast.success(`Post deleted successfully.`)
    }).catch(err => {
      console.error('Error message: ', err)
      toast.error(`Error occurred. Could not delete post.`);
    });
  };




  return (
    <ButtonContainer>

      {
        handlePreview &&
        <ButtonComponent
          bgColor={whiteColor}
          text="Preview"
          style={{
            border: `2px solid ${primaryColor}`,
            margin: "auto 4px",
            color: tertiaryColor
          }}
          click={handlePreview}
          isLoading={isLoading}
        />
      }

      {!isWriting ?
        handleUpdate &&
        <ButtonComponent
          bgColor={primaryColor}
          text="Update"
          style={{
            border: `2px solid ${primaryColor}`,
            margin: "auto 4px"
          }}
          click={handleUpdate}
          isLoading={isLoading}
        />
        :
        handlePublish && <ButtonComponent
          bgColor={primaryColor}
          text="Publish"
          style={{
            border: `2px solid ${primaryColor}`,
            margin: "auto 4px"
          }}
          click={handlePublish}
          isLoading={isLoading}
        />


      }

      {/* Show Publish button ONLY if slug === draft */}

      {
        slug === "draft" &&
        <>
          {
            handlePublish &&
            <ButtonComponent
              bgColor={primaryColor}
              text="Publish"
              style={{
                border: `2px solid ${primaryColor}`,
                margin: "auto 4px"
              }}
              click={(e) => {
                handlePublish(e);
                deleteDraft();
              }}
              isLoading={isLoading}
            />
          }
        </>
      }

    </ButtonContainer>
  )
}