import { Container } from 'react-bootstrap';
import { HorizontalRule } from '../../utils/horizontalrule/HorizontalRule';

export default function ContactTitle() {
	return (
		<>
			<Container>

				<HorizontalRule
					text="Contact Us"
					textColor="#727272"
				/>
			</Container>
		</>
	)
}