import { useEffect, useState } from 'react';
import styled from 'styled-components';










const FormContainer = styled.div`
  width: 90%;

  @media (min-width: 1024px) {
    width: 70%;
  }
`;


export default function Right({
  formTitle,
  formSrc,
}) {

  const [title, setTitle] = useState(`Eraconneckt | ${formTitle}`);

  useEffect(() => {
    document.title = title;
    setTitle(title);
  }, [title]);

  return (
    <FormContainer
      style={{}}
    >
      <iframe
        title={formTitle}
        width="640px"
        height="780px"
        src={formSrc}
        frameBorder="0"
        marginWidth="0"
        marginHeight="0"
        style={{
          border: "none",
          maxWidth: "100%",
          maxHeight: "100vh"
        }}
        allowfullscreen="true"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        msallowfullscreen="true"
      >
      </iframe>
    </FormContainer>
  )
}
