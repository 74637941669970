export const useLocationPathName = (location) => {


  const pathLength = location?.pathname?.split("/")?.length;
  const string = location?.pathname?.split("/")[2];
  const firstLetter = string?.charAt(0)?.toLocaleUpperCase();
  const lastLetters = string?.slice(1);
  const urlParams = new URLSearchParams(window?.location?.search);
  const surveyType = urlParams?.get('type');

  const result = firstLetter + lastLetters

  return { result, pathLength, surveyType };
};