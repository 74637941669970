import { toast } from "react-toastify";
import authService from "../services/auth.service";
import { ADMIN, AUTH, BLOG, GET_STARTED, ADMIN_JOB, ADMIN_BLOG, RESET_LINK_SENT } from "../routes";
import { pageErrors } from "../constants/constants";







export const reducer = (state, action) => {
  switch (action.type) {
    case "GET_STARTED":
      return {

      };
    case "SIGN_UP_TO_NEWSLETTER":
      return {
        count: state.count + 1,
        showText: !state.showText
      };
    default:
      return state;
  }
};

export const redirectToGetStarted = (e) => {
  e.preventDefault();
  window.location.href = GET_STARTED;
};

export const redirectToBlog = (e) => {
  e.preventDefault();
  window.location.href = BLOG;
};

export const authRedirection = () => {
  authService.logout();

  window.location.href = AUTH;

};

export const HRAdminRedirection = () => {
  window.location.href = ADMIN_JOB;
};

export const CMSAdminRedirection = () => {
  window.location.href = ADMIN_BLOG;
};

export const adminRedirection = () => {
  window.location.href = ADMIN;
};



export const redirectToResetLinkSent = (userEmail) => {
  window.location.href = `${RESET_LINK_SENT}?email=${userEmail}`;
};

export const inactiveUserAction = () => {
  // Clear localStorage details
  localStorage.removeItem("user");
  localStorage.removeItem("token");
  localStorage.removeItem("isAuthenticated");
  toast.error(pageErrors.ACCOUNT_IS_DISABLED);
}