import styled from 'styled-components';
import icon from '../../../src/assets/icons/research.svg';
import '../../utils/subtitle/SubTitle.css';


const ObjectiveContainer = styled.div`
		display: flex;
		flex-direction: row;
		justify-content: start;
    margin-bottom: 1.225rem;
	`;

const Icon = styled.div`
		flex-basis: 90px;
		width: 90px;
		height: 90px;
	`;

const Content = styled.div`
		flex-basis: calc(100% - 90px);
		margin-left: 0.825rem;
	`;

const Process = ({ title, text }) => {

  const primaryColor = "#89b369";
  return (
    <ObjectiveContainer
      data-aos="fade-up"
      data-aos-once="true"
    >

      <Icon>
        <img
          src={icon}
          alt={title}
          style={{ width: "100%", height: "100%" }}
        />

      </Icon>
      <Content>
        <h3
          style={{ color: primaryColor, fontWeight: "700" }}
        >
          {title}
        </h3>
        <p
          className="paragraph"
        >
          {text}
        </p>
      </Content>
    </ObjectiveContainer>
  )
}

export default Process;