

// entry-level, intern, intermediate, senior
export const jobLevel = [
  { value: "Entry level", label: "Entry level" },
  { value: "Intern", label: "Intern" },
  { value: "Intermediate", label: "Intermediate" },
  { value: "Senior", label: "Senior" },
]


// Part time, Full time, contract
export const supportedJobType = [
  { value: "Part time", label: "Part time" },
  { value: "Full time", label: "Full time" },
  { value: "Contract", label: "Contract" },
]


// onsite, hybrid, remote
export const supportedJobLocation = [
  { value: "Onsite", label: "Onsite" },
  { value: "Hybrid", label: "Hybrid" },
  { value: "Remote", label: "Remote" },
]