import React, { useEffect, useState } from 'react'
import ButtonComponent from '../../../utils/button/ButtonComponent';
import { useLocation } from "react-router-dom";
import { useLocationPathName } from '../../../hooks/useLocationPathname';
import { Colors } from '../../../utils/colors/colors';
import Navigation from '../../navbar';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import Footer from '../../footer';
import JobsCards from './job-cards/JobsCards';
import { ADMIN_CREATE_JOB } from '../../../routes';








export default function JobPageComponents() {




  const location = useLocation();

  const { result } = useLocationPathName(location);

  const [title, setTitle] = useState("Eraconneckt | Jobs");

  const {
    primaryColor,
  } = Colors()


  useEffect(() => {


    setTitle(`Eraconneckt | Jobs`);

  }, []);


  useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    document.title = title;
  }, [title]);



  return (
    <>
      <Navigation />

      <Container>
        <Header
          className="heading"
        >
          <p
            style={{
              marginTop: 0,
              marginBottom: 0,
            }}
          >
            {result}
          </p>

          <LinkWrapper>
            <ButtonComponent
              href={ADMIN_CREATE_JOB}
              text={`Post a Job`}
              bgColor={primaryColor}
              style={``}
              click={``}
              variant={``}
              isLoading={``}
              cName={`border-transparent`}
            />

          </LinkWrapper>
        </Header>
      </Container>


      <div
        style={{
          position: "relative",
          top: "25vh"
        }}>

        <JobsCards />

      </div>

      <Footer />

    </>
  )
}




const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 7rem;
`;

const LinkWrapper = styled.div`
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
`;