import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';



const ImageContainer = styled.div`
  background-size: cover;
  background-repeat: no-repeat;
  min-width: 300px;
  min-height: 70vh;
  width: 100%;
  height: 100%;
  background-color: transparent;
`;


export default function FeaturedImage({ featuredImage }) {

  return (
    <>
      <Container>
        <Row>
          <Col
            xs={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
            style={{
              margin: "1rem auto",
              minWidth: "100%",
              maxWidth: "100%",
            }}
          >

            <ImageContainer
              style={{
                backgroundImage: `url(${featuredImage})`
              }}
            >

            </ImageContainer>

          </Col>
        </Row>
      </Container>
    </>
  )
}