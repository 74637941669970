import { Card } from 'react-bootstrap';
import { Colors } from '../../../utils/colors/colors';


export default function StatCard({ statImg, statText }) {


  const {
    fourthColor
  } = Colors();





  return (
    <>
      <Card
        style={{
          border: "0"
        }}
      >
        <Card.Img
          variant="top"
          alt="statistics"
          src={statImg}
          style={{
            width: "125px",
            height: "125px",
            margin: "auto"
          }}
        />
        <Card.Body>
          <Card.Text
            style={{
              color: fourthColor,
              textAlign: "center",
              fontWeight: "700"
            }}
          >
            {statText}
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  )
}