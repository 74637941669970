import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { HorizontalRule } from '../../utils/horizontalrule/HorizontalRule';
import Process from './Process';

const Processes = () => {

	return (
		<section
			id="processes"
			className="section processes-section"
		>
			<Container>

				<HorizontalRule
					text="Our Processes"
					textColor="#727272"
				/>

				<Row
					className="mt-5"
				>
					<Col
						xs={{ span: 12 }}
						md={{ span: 6 }}
					>
						<Process
							title="Research"
              text="Through an innovative and systematic collection and analysis of data, Eraconneckt researches the labour market's shortages and ways to improve the workforce through effective human resource development."
						/>
					</Col>

					<Col
						xs={{ span: 12 }}
						md={{ span: 6 }}
					>
						<Process
							title="Build"
              text="Eraconneckt creates sustainable and effective systems through capacity building to grow and strengthen the skills, resources, and abilities required for the transition to the digital age."
						/>
					</Col>
				</Row>

				<Row>
					<Col
						xs={{ span: 12 }}
						md={{ span: 6 }}
					>
						<Process
							title="Conneckt"
              text="By removing barriers and bridging gaps, Eraconneckt helps the right people and the right opportunities connect."
						/>
					</Col>

					<Col
						xs={{ span: 12 }}
						md={{ span: 6 }}
					>
						{/* <Process
							title="Join the eWfRP"
							text="The EraConneckt Workforce Readiness Program (eWRP) harnesses the power of technology to create a multidisciplinary environment that provides training and practical experience in multiple digital skills."
						/> */}
					</Col>

				</Row>
			</Container>
		</section>

	);
};

export default Processes;