import AuthCard from '../../AuthCard';
import FormComponent from './FormComponent';


const Right = () => {
  return (
    <AuthCard
      actionText="Create Admin Account"
      actionSubText=""
    >
      <FormComponent>

      </FormComponent>
    </AuthCard>
  );
}

export default Right;