
const useDate = () => {

	var greet;
	var date = new Date();
	var hour = date.getHours();
	var year = date.getFullYear();

	if (hour === 5 || hour === 6 || hour === 7 || hour === 8 || hour === 9 || hour === 10 || hour === 11) {
		greet = 'Good morning';

	} else if (hour === 12) {
		greet = 'Good noon';

	} else if (hour === 13 || hour === 14 || hour === 15 || hour === 16 || hour === 17) {
		greet = 'Good afternoon';

	} else if (hour === 18 || hour === 19 || hour === 20 || hour === 21 || hour === 22 || hour === 23 || hour === 0 || hour === 1 || hour === 2 || hour === 3 || hour === 4) {
		greet = 'Good evening';

	}

	return { greet, year, };
};

export default useDate;