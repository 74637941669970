import styled from 'styled-components';
import { Colors } from '../../../utils/colors/colors';

const TestStyle = styled.div`
`;

const {
  whiteColor
} = Colors();



export default function Text({ timelineHeading, timelineText, timelineTextTwo, rightText }) {

  return (
    <>
      <TestStyle
        className="text"
      >

        <h3
          className="heading"
          style={{
            color: whiteColor,
          }}
        >
          {timelineHeading}
        </h3>

        <p
          style={{}}
        >
          {timelineText}
        </p>

        <p
          style={{}}
          className="mt-3">
          {timelineTextTwo}
        </p>

      </TestStyle>
    </>
  )
}