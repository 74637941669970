import styled from 'styled-components';
import { useExport } from '../../hooks/useExport';
import { Colors } from '../../utils/colors/colors';



const Aside = styled.aside`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 100%;
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
`;

const AsideBoxShadow = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  min-height: 100%;
  min-width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0.8;
  z-index: 0;
`;

const Img = styled.img`
  z-index: 2;
`;

export const AuthAside = () => {

  const {
    primaryColor,
  } = Colors();

  const {
    asideBg,
    logo,
  } = useExport();


  return (
    <>
      <Aside
        style={{
          backgroundImage: `url(${asideBg})`,
        }}
      >
        <Img
          src={logo}
          alt="Eraconneckt Logo"
          style={{}}
        />

        <AsideBoxShadow
          style={{
            backgroundColor: `${primaryColor}`
          }}
        >

        </AsideBoxShadow>

      </Aside>

    </>
  );
}
