import React from "react"
import { Container, Row, Col } from "react-bootstrap";
import ReactPaginate from 'react-paginate';



const PageNavigation = ({
  isPending,
  error,
  data,
  handlePageClick,
  pageCount,
  pageOffset
}) => {


  return (

    <>
      {!isPending && data &&
        <Container
          style={{
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
        >
          <Row>
            <Col
              className="d-flex justify-content-center align-items-center"
            >
              <ReactPaginate
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                renderOnZeroPageCount={null}
                previousLabel="‹"
                nextLabel="›"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                marginPagesDisplayed={2}
                containerClassName="pagination"
                activeClassName="active"
                forcePage={pageOffset}
              />
            </Col>
          </Row>
        </Container>
      }
    </>

  )
}

export default PageNavigation