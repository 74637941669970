import Survey from "../../components/survey-form/form";



export default function SurveyPage() {
  return (
    <>
      <Survey />
    </>
  )
}