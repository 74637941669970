import SubTitle from '../../../utils/subtitle/SubTitle';

export default function ThirdCol() {
  return (
    <div
      data-aos="fade-up"
      data-aos-once="true"
    >
      <SubTitle
        textColor=""
        text="Visit Us"
        textAlign="left"
        style={{ fontWeight: "bold" }}
      />

      <div
        className="footer-link"
      >
        Eridan Space, Obafemi Awolowo Way,
      </div>

      <div
        className="footer-link"
      >
        Ikeja, Lagos, Nigeria.
      </div>
    </div>
  )
}