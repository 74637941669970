import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { Icons } from '../../../../hooks/useIcons.js';
import { Colors } from '../../../../utils/colors/colors';
import ButtonComponent from '../../../../utils/button/ButtonComponent';
import authService from '../../../../services/auth.service';
import Select from 'react-select';
import { roles, rolesSelectOptions } from '../../../../constants/constants.js';
















const FormContainer = styled.div`
  width: 90%;

  @media (min-width: 1024px) {
    width: 70%;
  }
`;

const FormContent = styled.div`

`;

export default function Right() {

  const [authError, setAuthError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [otherError, setOtherError] = useState("");
  const [seePassword, setSeePassword] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const { register, formState: { errors }, handleSubmit } = useForm();



  const handleRole = (selectedRole) => {
    setSelectedRole(selectedRole);
  };

  const onSubmit = async (data) => {
    setIsLoading(true);


    try {
      await authService.signup(data.email, data.password, selectedRole.value).then(
        () => {
          // Successful signup
        },
        (error) => {
          console.error(error);
          setOtherError(error?.message);
          setAuthError(error?.response.data.error);
          setIsLoading(false);
        }
      );

    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    otherError && setIsLoading(false);
  }, [otherError]);

  const {
    lightGrayColor,
    whiteColor,
    primaryColor,
  } = Colors();

  const {
    FaRegEye,
    FaRegEyeSlash,
  } = Icons();

  return (
    <FormContainer
      style={{}}
    >
      <FormContent
        style={{
          backgroundColor: whiteColor,
          width: "100%",
          padding: "1rem",
          paddingTop: "0"
        }}
      >


        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">

            <Form.Label
              style={{
                margin: "12px auto",
                color: lightGrayColor,
              }}
            >
              Email address
            </Form.Label>

            <Form.Control
              className={`${errors.email ? 'input-error' : ''}`}
              name="email"
              {...register("email", { required: "Please enter email" })}
              type="text"
              autoComplete="off"
              placeholder="Enter email"
              style={{
                backgroundColor: whiteColor,
                padding: "12px 28px",
              }}
            />

            {errors.email && (
              <p className="errorMsg">{errors.email.message}</p>
            )}


            <Form.Label
              style={{
                margin: "12px auto",
                color: lightGrayColor,
              }}
            >
              Role
            </Form.Label>


            <Select
              options={rolesSelectOptions}
              name="role"
              type="text"
              autoComplete="off"
              placeholder="Select role"
              style={{
                backgroundColor: whiteColor,
                padding: "12px 28px",
              }}
              onChange={handleRole}
            />

            <Form.Label
              style={{
                margin: "12px auto",
                color: lightGrayColor,
              }}
            >
              Password
            </Form.Label>

            <Form.Control
              className={`${errors.password ? 'input-error' : ''}`}
              name="password"
              {...register("password", { required: "Please input password" })}
              type={seePassword ? 'text' : 'password'}
              autoComplete="off"
              placeholder="Enter password"
              style={{
                backgroundColor: whiteColor,
                padding: "12px 28px",
              }}
            />

            {seePassword ?
              <FaRegEyeSlash
                className="auth-icon"
                onClick={() => setSeePassword(false)}
              />
              :
              <FaRegEye
                className="auth-icon"
                onClick={() => setSeePassword(true)}
              />}

            {errors.password && (
              <p className="errorMsg">{errors.password.message}</p>
            )}


          </Form.Group>


        </Form>


        {authError &&
          (<p
            className="errorMsg"
          >
            Error occurred: {" "}
            {authError}
          </p>
          )
        }

        {otherError &&
          (<p
            className="errorMsg"
          >
            Error occurred: {" "}
            {otherError}
          </p>
          )
        }



        <ButtonComponent
          bgColor={primaryColor}
          text={!isLoading ? "Create admin" : "Creating admin ..."}
          style={{
            minWidth: "100%",
            margin: "2.125rem auto 1rem auto",
          }}
          click={handleSubmit(onSubmit)}
          isLoading={isLoading}
        />

      </FormContent>
    </FormContainer>
  )
}