import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-toastify/dist/ReactToastify.css";
import './index.css';
import App from './App';



const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);