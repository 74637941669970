import styled from 'styled-components';
import videoSrc from '../../../assets/video/story.mp4';



const Overlay = styled.div`
	position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 2;
  cursor: pointer;
`;

const Close = styled.span`
	position: absolute;
	top: 0;
	right: 0;
	margin: 4px;
	border: 2px solid #333;
	padding: 4px 8px;
	border-radius: 2px;
  z-index: 9;
`;

const VideoContainer = styled.div`
	width: 100%;
  height: 100%;
`;

const videoStyles = {
	minWidth: "300px",
	width: "100%",
	maxWidth: "100%",
	minHeight: "300px",
	height: "100%",
	maxHeight: "100%",
	padding: "4px",
};



export default function VideoOverlay({ showVideo, setShowVideo }) {
	return (

		<Overlay
		>
			<Close
				title="Close"
				onClick={() => setShowVideo(false)}
			>
				x
			</Close>


			<VideoContainer>
				<video
					src={videoSrc}
					controls={true}
					style={videoStyles}
					autoPlay={true}
				/>
			</VideoContainer>

		</Overlay>
	)
}