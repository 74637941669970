import React from 'react'
import { DialogOverlay, DialogContent } from "@reach/dialog";
import "@reach/dialog/styles.css";
import styled from 'styled-components';


function Modal({
  open,
  children,
  openModal,
  closeModal
}) {




  return (
    <div>
      <VisuallyHidden>
        <button type={"button"} onClick={openModal}>Open Dialog</button>
      </VisuallyHidden>

      <DialogOverlay
        style={{
          zIndex: "333"
        }}
        isOpen={open} onDismiss={closeModal}>
        <DialogContent
          style={{
            boxShadow: "0px 10px 50px hsla(0, 0%, 0%, 0.33)",
          }}
        >
          <VisuallyHidden>
            <button className="close-button" title="Close modal" onClick={closeModal}>
              <span aria-hidden>×</span>
            </button>
          </VisuallyHidden>




          {/* Rendered content START */}
          {children}
          {/* Rendered content END */}
        </DialogContent>
      </DialogOverlay>
    </div>
  );
}



export default Modal

const VisuallyHidden = styled.div`
  display: none;
`