import SubTitle from '../../../utils/subtitle/SubTitle';

export default function FourthCol() {
  return (
    <div
      data-aos="fade-up"
      data-aos-once="true"
    >
      <SubTitle
        textColor=""
        text="Contact Us"
        textAlign="left"
        style={{ fontWeight: "bold" }}
      />

      <div>
        Email:&nbsp;
        <a
          hrefLang="en-us"
          className="footer-link"
          href="mailto:info@eraconneckt.com">
          info@eraconneckt.com
        </a>
      </div>

      <div>
        Phone:&nbsp;
        <a
          hrefLang="en-us"
          className="footer-link"
          href="tel:+2348136854728">
          (+234)813-685-4728
        </a>
      </div>
    </div>
  )
}