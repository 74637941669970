import AuthCard from '../../AuthCard';
import FormComponent from './FormComponent';


const Right = () => {
  return (
    <AuthCard
      actionText="Create New Password"
      actionSubText="Enter a new password"
    >
      <FormComponent>

      </FormComponent>
    </AuthCard>
  );
}

export default Right;