import React from 'react'
import Buttons from '../../../blog/components/blog-tools/components/Buttons';
import { Container, Row, Col } from 'react-bootstrap';
import styled from "styled-components";
import { toast } from "react-toastify";
import SubTitle from '../../../../utils/subtitle/SubTitle';
import { useExport } from '../../../../hooks/useExport';
import { Colors } from '../../../../utils/colors/colors';
import postService from '../../../../services/post.service';
import Select from 'react-select';
import { jobLevel, supportedJobLocation, supportedJobType } from '../../../blog/data/jobData';










export default function JobTools({
  level,
  id,
  jobLocation,
  setJobLocation,
  jobType,
  setLevel,
  isLoading,
  setJobType,
  handleUpdate,
  handlePublish,
  isCreating,
  postError,
  slug

}) {



  const handleDeleteJob = () => {

    // eslint-disable-next-line no-restricted-globals
    const confirmation = confirm(`Are you sure you want to delete?`);

    if (confirmation === true) {
      toast.info(`Deleting job post`)

      postService.deleteJob(id).then(() => {
        toast.success(`Job deleted successfully.`);
        window.location.reload();
      })
        .catch((error) => {
          console.error(error)
          toast.error(`Did not delete`)
        })
        .finally(() => {
        })
    } else {
      console.info("Delete aborted");
      toast.info("Delete aborted");
    }
  }


  const {
    primaryColor,
    dangerColor,
    whiteColor
  } = Colors();

  const { trashIcon } = useExport();



  return (
    <>
      <section
        className="section write-blog-section"
        id="write-blog"
      >
        <Container>
          <Row>
            <Col
              xs={{ span: 12, order: 1 }}
              md={{ span: 12, order: 1 }}
              lg={{ span: 12, order: 1 }}
            >
              <ActionArea>

                <SubTitle
                  textColor={primaryColor}
                  text={!isCreating ? `Edit Job` : `Create Job`}
                  textAlign="left"
                  style={{
                    fontWeight: "bold",
                    margin: "0"
                  }}
                />

                {/* Only show delete container if !isCreating */}
                {!isCreating &&
                  <DeleteContainer
                    onClick={handleDeleteJob}
                  >

                    <Img
                      src={trashIcon}
                      alt="Delete-icon"
                    />

                    <DeleteText>
                      Delete
                    </DeleteText>
                  </DeleteContainer>
                }

                <ErrorContainer>
                  {postError
                    &&
                    <SubTitle
                      textColor={dangerColor}
                      text={postError ? postError : `ERROR! Please fill out all input fields.`}
                      textAlign="right"
                      style={{
                        fontWeight: "bold",
                        margin: "0",
                        float: "right",
                      }}
                    />
                  }

                </ErrorContainer>

              </ActionArea>
            </Col>

          </Row>
        </Container>



        <Container
          className="mt-5"
        >
          <Row>

            <Col
              xs={{ span: 12 }}
              md={{ span: 6 }}
              lg={{ span: 3 }}
            >

              <InputName>
                Level
              </InputName>
              <InputBorder>

                <Select
                  defaultInputValue={level}
                  className={`blog-tools-form__input`}
                  options={jobLevel}
                  name="level"
                  type="text"
                  autoComplete="off"
                  placeholder="Select level"
                  style={{
                    backgroundColor: whiteColor,
                    padding: "12px 28px",
                  }}
                  onChange={(value) => {
                    setLevel(value.value)
                  }}
                />
              </InputBorder>

            </Col>


            <Col
              xs={{ span: 12 }}
              md={{ span: 6 }}
              lg={{ span: 3 }}
            >

              <InputName>
                Job Type
              </InputName>
              <InputBorder>

                <Select
                  defaultInputValue={jobType}
                  className={`blog-tools-form__input`}
                  options={supportedJobType}
                  name="jobType"
                  type="text"
                  autoComplete="off"
                  placeholder="Select job type"
                  style={{
                    backgroundColor: whiteColor,
                    padding: "12px 28px",
                  }}
                  onChange={(value) => {
                    setJobType(value.value)
                  }}
                />
              </InputBorder>

            </Col>



            <Col
              xs={{ span: 12 }}
              md={{ span: 6 }}
              lg={{ span: 3 }}
            >

              <InputName>
                Location
              </InputName>
              <InputBorder>

                <Select
                  defaultInputValue={jobLocation}
                  className={`blog-tools-form__input`}
                  options={supportedJobLocation}
                  name="location"
                  type="text"
                  autoComplete="off"
                  placeholder="Select location"
                  style={{
                    backgroundColor: whiteColor,
                    padding: "12px 28px",
                  }}
                  onChange={(value) => {
                    setJobLocation(value.value)
                  }}
                />
              </InputBorder>

            </Col>


            <Col
              xs={{ span: 12 }}
              md={{ span: 6 }}
              lg={{ span: 3 }}
            >

              <InputName
                style={{
                  visibility: "hidden"
                }}
              >
                Buttons
              </InputName>
              <InputBorder
                style={{
                  border: "none",
                }}
              >
                <Buttons
                  handlePublish={handlePublish}
                  isWriting={isCreating}
                  handleUpdate={handleUpdate}
                  slug={slug}
                  isLoading={isLoading}
                />
              </InputBorder>

            </Col>


          </Row>
        </Container>

      </section>
    </>
  )
};




const ActionArea = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
`;

const DeleteContainer = styled.div`
  margin-left: 3.8rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
`;

const ErrorContainer = styled.div`
  margin-left: 3.8rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const Img = styled.img`
  max-width: 16px;
  max-height: 16px;
  margin-right: 0.425rem;
`;

const DeleteText = styled.div`
  color: #FF5151;
`;

const InputBorder = styled.div`
  border: 1px solid #848484;
  padding: 0;
  height: 56px;
  margin-bottom: 1.525rem;
  border-radius: 4px;
`;

const InputName = styled.p`
  color: #1c1c1c;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 0.825rem;
`;

