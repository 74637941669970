import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import SubTitle from '../../utils/subtitle/SubTitle';
import { useForm, ValidationError } from '@formspree/react';
import styled from 'styled-components';

const Success = styled.div`
	background-color: #89B369;
	color: white;
	padding: 20px;
	margin: 0;
	text-align: center;
	width: 100%;
`;


export default function ContactForm() {

  const [state, handleSubmit] = useForm("mvoldqwr");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const formStyles = {
    formControl: {
      fontSize: "1rem",
      fontWeight: "400",
      color: "#212529",
      backgroundColor: "#fff",
      border: "0.5px solid rgba(79, 79, 79, 0.4)",
      borderRadius: "2px",
      marginBottom: "2rem",
      padding: "0.375rem 0.75rem",
      input: {
        height: "50px",
      },
      textarea: {

      }
    }
  };

  const buttonStyles = {
    backgroundColor: "rgb(137, 179, 105)",
    border: 0,
    outline: 0,
    maxWidth: "170px",
    padding: "16px 28px",
    color: "#fff",
    minWidth: "48px",
    borderRadius: "4px",
    cursor: "pointer",
    fontSize: "0.875rem",
  };

  if (state.succeeded) {
    return (
      <>
        <Success
          data-aos="fade-up"
          data-aos-once="true"
        >
          Thank you for contacting us, we will be in touch!
        </Success>
      </>
    )
      ;
  }


  return (
    <div
      style={{ margin: "3rem 1rem" }}
    >

      <SubTitle
        textColor="#4f4f4f"
        text="Send us a message:"
        textAlign="left"
        style={{ marginBottom: "2rem", fontSize: "18px" }}
      />

      <Form
        onSubmit={handleSubmit}
      >
        <Form.Group>
          <Form.Label
            style={{ display: "none" }}
          >
          Enter your full name:
          </Form.Label>

          <Form.Control
            style={formStyles.formControl}
            placeholder="Name"
            type="text"
            name="name"
            onChange={(e) => setName(e.target.value)}
            value={name}
            required
          />
          <ValidationError
            prefix="Name"
            field="name"
            errors={state.errors}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label
            style={{ display: "none" }}
          >Enter your email address:
          </Form.Label>

          <Form.Control
            style={formStyles.formControl}
            placeholder="Email Address"
            type="email"
            name="email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            required
          />
          <ValidationError
            prefix="Email"
            field="email"
            errors={state.errors}
          />
        </Form.Group>

        <Form.Group controlId="exampleForm.ControlTextarea1">
          <Form.Label
            style={{ display: "none" }}

          >
            Message
          </Form.Label>
          <Form.Control
            as="textarea"
            rows="7"
            placeholder="Message"
            name="message"
            onChange={(e) => setMessage(e.target.value)}
            value={message}
            required
            style={formStyles.formControl}
          />
          <ValidationError
            prefix="Message"
            field="message"
            errors={state.errors}
          />
        </Form.Group>


        <button
          type="submit"
          disabled={state.submitting}
          style={buttonStyles}
        >
          Send message
        </button>

      </Form>

    </div>
  );
}