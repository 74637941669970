import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Colors } from '../../utils/colors/colors';
import Text from './contents/Text';
import Video from './contents/Video';
import './VideoContent.css';




function VideoContent() {

  const {
    primaryColor,
    tertiaryColor
  } = Colors();

  const videoContentStyles = {
    textStyles: {
      fontFamily: "Lato",
      title: {
        fontWeight: "900",
        marginBottom: "0.625rem",
      },
      paragraph: {
        fontWeight: "400",
        marginTop: "1rem",
      }
    }
  };

  return (
    <>
      <section
        id="video"
        className="section video-content-section">
        <Container>
          <Row
            style={videoContentStyles.textStyles}
          >
            <Col
              xs={{ span: 12, order: 2 }}
              md={{ span: 6, order: 1 }}
            >
              <Text
                className="video-content-child"
                textColor={primaryColor}
                text="Eraconneckt is designed for Africans"
                textAlign="left"
                style={videoContentStyles.textStyles.title}
                subTitleTextColor={tertiaryColor}
                subTitleTextOne="Eraconneckt believes that Africa houses the great leaders and innovators of tomorrow and therefore exists primarily to create sustainable solutions to the economic and human capital problems of Africa."
                subTitleTextTwo="Through its unique system relying on the power of digital transformation, Eraconneckt connects people to the resources of the new era, which have the potential to provide job opportunities across the African continent."
                subTitleTextAlign="justify"
                subTitleStyle={videoContentStyles.textStyles.paragraph}
              />
            </Col>

            <Col
              xs={{ span: 12, order: 1 }}
              md={{ span: 6, order: 2 }}
            >
              <Video />
            </Col>

          </Row>
        </Container>
      </section>
    </>
  );
}

export default VideoContent;