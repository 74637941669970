import { Container, Row, Col } from 'react-bootstrap';
import Article from './post-body/article';
import SocialSharing from './post-body/social-sharing';

export default function ArticleContainer({
  id,
  blogTitle,
  blogBody,
  date,
  timeToRead,
  author
}) {
  return (
    <>
      <Container>
        <Row>
          <Col
            xs={{ span: 12 }}
            md={{ span: 2 }}
            lg={{ span: 2 }}
          >
            <SocialSharing
              id={id}
              blogTitle={blogTitle}
            />
          </Col>

          <Col
            xs={{ span: 12 }}
            md={{ span: 10 }}
            lg={{ span: 10 }}
          >
            <Article
              id={id}
              blogTitle={blogTitle}
              blogBody={blogBody}
              date={date}
              timeToRead={timeToRead}
              author={author}
            />
          </Col>

        </Row>
      </Container>

    </>
  )
}