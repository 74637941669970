export const useCreatedAt = (createdAt) => {

  const milliseconds = createdAt * 1000 // 1575909015000

  const dateObject = new Date(milliseconds);

  const day = dateObject.toLocaleString("en-US", { day: "numeric" }); // 9
  const month = dateObject.toLocaleString("en-US", { month: "long" }); // December
  const year = dateObject.toLocaleString("en-US", { year: "numeric" }); // 2019

  const date = `${month} ${day} ${year}`;

  return date
};